import React from "react";
import "./DailyServiceCell.scss"
import IconWorker from "../../../../icons/IconWorker";
import IconVehicle from "../../../../icons/IconVehicle";
import IconError from "../../../../icons/IconError";

interface Props {
  name: string
  types: string[]
  actualHumanNeed: number
  actualVehicleNeed: number
  expectedHumanNeed?: number
  expectedVehicleNeed?: number
  showExpected?: boolean
  onSelect?: () => void
  onSelectContainerVehicle?: (worker:boolean) => void
  errors?:string[]
  warnings?:string[]
}

export default function DailyServiceCell(props: Props) {


  function removeDuplicatesAndDash(types: string[]) {
    return types.filter(function (value, index, array) {
      return (array.indexOf(value) === index) && value != "-";
    });
  }

  let types = removeDuplicatesAndDash(props.types).map(types => types ? types.toUpperCase():"");

  return <div className="DailyServiceCell"
              style={{cursor: props.onSelect ? "pointer" : "default"}}
              onClick={() => props.onSelect ? props.onSelect() : null}>

    {(props.errors && props.errors.length > 0) && <div className="DailyServiceCell-error" title={props.errors.join("\n")}>
      <IconError />
    </div>}

    <div className="DailyServiceCell-serviceTypeContainer">
      {types.map((type, i) =>
        <span key={i}
              className={"DailyServiceCell-serviceType " + (type == "EM+" ? "EM_PLUS" : type)}>
          {type}
        </span>)}
    </div>
    <div className="DailyServiceCell-serviceName">{props.name}</div>
    <div className="DailyServiceCell-need">
      {props.showExpected
        ? <>
            <span className={props.actualHumanNeed > props.expectedHumanNeed
              ? "warning" : props.actualHumanNeed < props.expectedHumanNeed
                ? "error" : ""}><IconWorker/> {props.actualHumanNeed}/{props.expectedHumanNeed}</span>
            <span className={props.actualVehicleNeed > props.expectedVehicleNeed
              ? "warning" : props.actualVehicleNeed < props.expectedVehicleNeed
                ? "error" : ""}><IconVehicle/> {props.actualVehicleNeed}/{props.expectedVehicleNeed}</span>
        </>
        : <>
          <span className="actionable" onClick={()=>props.onSelectContainerVehicle(true)} ><IconWorker/> {props.actualHumanNeed}</span>
          <span className="actionable" onClick={()=>props.onSelectContainerVehicle(false)}  ><IconVehicle/> {props.actualVehicleNeed}</span>
        </>}
    </div>
  </div>
}



