import AuthorizeApi from "../AuthorizeApi";
import {DateTime} from "luxon";
import {
  DailyPlanningWorkerDto,
  DailyPlanningWorkerDtoNt,
  DailyPlanningWorkerResponse,
  DailyPlanningWorkerResponseNt
} from "../entity/response/nt/DailyPlanningWorkerResponseNt";
import {DailyFindingRequest} from "../entity/request/DailyFindingRequest";
import Time from "../../lib/utils/Time";
import { testRegex } from "../../../jest.config";

export const coursesMatches = {
  "morning": /^C\d*\/P\d*$/,
  "afternoon": /^M\d*\/C\d*$/,
  "allDay": /^C01$/
};

export default class DailyPlanningApi {

  private base: string = '/daily-planning-worker';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getWorkersByDate(date: DateTime, onlyExactDate: boolean = false): Promise<DailyPlanningWorkerDtoNt[]> {
    const response = await this.authApi.get<DailyPlanningWorkerResponseNt>(`${this.base}/${date.toFormat("yyyy-MM-dd")}`);

    const dailyWorkers = []

    for(const d of response.content){
      if(d.date == date.toFormat("yyyy-MM-dd")){
        dailyWorkers.push(d)
      }
    }

    if (!onlyExactDate) {
      for(const d of response.content){
        if (d.date == date.minus( {day:1}).toFormat("yyyy-MM-dd")){
          if(d.shiftstart && d.shiftend && Time.hourStringToNumber(d.shiftstart)>=Time.hourStringToNumber(d.shiftend)){
            d.shiftstart="00:00:00"
            d.servicestart="00:00:00"
            d.date=date.toFormat("yyyy-MM-dd")
            d.previousDay = true;
            dailyWorkers.push(d)
          }
        }
      }
    }

    return dailyWorkers
  }

  public isReperible(worker: DailyPlanningWorkerDtoNt): boolean {
    if (worker.turn == null) {
      return false;
    }
    //reperible if worker turn starts with "R" or contains "/R" but not equals to "R" or "RN"
    if (worker.turn.startsWith("R") || worker.turn.includes("/R")){
      return worker.turn != "R" && worker.turn != "RN";
    }
    return false;
  }


  public isCourse(worker: DailyPlanningWorkerDtoNt): boolean {
    //Courses are workers that have turn: "C*/P16" or C01 or M1/C* (where * is any numbers)

    if (worker.turn == null) {
      return false;
    }

    if (worker.turn.match(coursesMatches.morning)
      || worker.turn.match(coursesMatches.afternoon)
      || worker.turn.match(coursesMatches.allDay)) {
      return true;
    }

    return false;
  }

  public async getReperibilityWorkersByDate(date: DateTime): Promise<DailyPlanningWorkerDtoNt[]> {
    const allWorkers = await this.getWorkersByDate(date, true);
    const reperibleWorkers = allWorkers.filter(this.isReperible)

    return reperibleWorkers;
  }

  public async getReperibilityAndCoursesWorkersByDate(date: DateTime): Promise<DailyPlanningWorkerDtoNt[][]> {
    const allWorkers = await this.getWorkersByDate(date, true);
    const reperibleWorkers = allWorkers.filter(this.isReperible)
    const coursesWorkers = allWorkers.filter(this.isCourse);

    return [reperibleWorkers, coursesWorkers];
  }

  public async getByWorkerIdAndDate(workerId: number, date: DateTime): Promise<DailyPlanningWorkerResponse> {
    let response;
    await this.authApi.post<DailyFindingRequest, DailyPlanningWorkerResponseNt>(`${this.base}/find`, {
      date: date.toFormat("yyyy-MM-dd"),
      workerId: workerId
    })
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      });
    return response
  }

  public async getContainerVehicle(date: DateTime): Promise<DailyPlanningWorkerResponse> {
    let response;
    await this.authApi.get<DailyPlanningWorkerResponseNt>(`${this.base}/container-vehicle/${date.toFormat("yyyy-MM-dd")}`)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;
  }

  public async create(record: DailyPlanningWorkerDto) {
    let response;
    await this.authApi.post<DailyPlanningWorkerDto, DailyPlanningWorkerResponseNt>(`${this.base}`, record)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;
  }

  public async delete(id: number) {
    let response;
    await this.authApi.delete<void, DailyPlanningWorkerResponseNt>(`${this.base}/` + id)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;
  }

  public async deleteByWorkerNameAndDate(fullName: string, day: DateTime) {
    let response;
    let dateFormatted = day.toFormat("yyyy-MM-dd");
    await this.authApi.delete<void, DailyPlanningWorkerResponseNt>(`${this.base}/worker/${fullName}/${dateFormatted}`)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;

  }

  public async update(record: DailyPlanningWorkerDto) {

    let response;
    await this.authApi.put<DailyPlanningWorkerDto, DailyPlanningWorkerResponseNt>(`${this.base}`, record)
      .then((w) => {
        response = DailyPlanningApi.mapResponse(w)
      })
    return response;
  }

  private static mapResponse(w: DailyPlanningWorkerResponseNt): DailyPlanningWorkerResponse {
    return {
      content: w.content.map(r => {
        return {
          id: r.id,
          date: r.date,
          companionid: r.companionid,
          workerid: r.workerid,
          workername: r.workername,
          containervehicle: r.containervehicle,
          shiftstart: r.shiftstart ? r.shiftstart.slice(0, 5) : "00:00",
          shiftend: r.shiftend ? r.shiftend.slice(0, 5) : "00:00",
          servicestart: r.servicestart ? r.servicestart.slice(0, 5) : "00:00",
          serviceend: r.serviceend ? r.serviceend.slice(0, 5) : "00:00",
          service: r.service,
          turn: r.turn,
          vehicle: r.vehicle,
          servicecategory: r.servicecategory,
          manuallyCreated: r.manuallyCreated,
          radiocodeid: r.radiocodeid,
          plannedTurn: r.plannedTurn
        }
      })
    }
  }

  public static getDefaultEmptyEntry(date: DateTime, workerId: number, workerName: string, containerVehicle? : string) : DailyPlanningWorkerDtoNt  {
    let defaultTime = "00:00";
    return {
      id: null,
      date: date.toFormat("yyyy-MM-dd"),
      companionid: null,
      workerid: workerId,
      workername: workerName,
      containervehicle: containerVehicle ? containerVehicle : null,
      shiftstart: defaultTime,
      shiftend: defaultTime,
      servicestart: defaultTime,
      serviceend: defaultTime,
      service: null,
      turn: null,
      vehicle: null,
      servicecategory: null,
      radiocodeid: 0,
      manuallyCreated: true,
      plannedTurn: null
    }
  }
}
