import AuthorizeApi from "../AuthorizeApi";
import {NeedResponse} from "../entity/response/NeedResponse";
import {NeedResponseNt, VehicleResponse} from "../entity/response/nt/NeedResponseNt";
import {NeedDto} from "../entity/NeedDto";
import {NeedVehicleRequest} from "../entity/request/NeedVehicleRequest";
import {NeedHumanRequest} from "../entity/request/NeedHumanRequest";
import {ReadRequest} from "../entity/request/ReadRequest";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";

export default class NeedManagementApi {

  private base: string = '/need';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getAllActiveInDay(date: DateTime, search: string = null) {
    return this.getAllActiveInRange(date.minus({day: 1}), date, search)
  }

  public async getAllActiveInMonth(date: DateTime, search: string = null) {
    return this.getAllActiveInRange(date, date.endOf("month"),search)
  }

  public async getAllActiveInRange(start: DateTime, end: DateTime, search: string = null) {
    const query =  search ? `?search=${search}` : ""
    const s = start.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    let e = end.toFormat(GfDateUtils.STORED_DATE_FORMAT);
    return NeedManagementApi.mapToNeedResponse(
      await this.authApi.get <NeedResponseNt>(`${this.base}/all/${s}/${e}/active${query}`))
  }

  public async getAllNeedVehicles(){
    return await this.authApi.get <VehicleResponse>(`${this.base}/vehicles`);
  }

  public async getById(id: string) {
    let needResponse: NeedResponse;
    await this.authApi.get<NeedResponseNt>(this.base + "/" + id)
        .then((w) => {
        needResponse = NeedManagementApi.mapToNeedResponse(w)
      });
    return needResponse;
  }

  public async getNotDeletedByCode(code: string) {
    let needResponse: NeedResponse;
    await this.authApi.get<NeedResponseNt>(this.base + "/code/" + code)
        .then((w) => {
        needResponse = NeedManagementApi.mapToNeedResponse(w)
      });
    return needResponse;
  }

  public async getOrSearchByServiceCodeOrCircuit(value: string, count: number, skip: number) {
    let needResponse: NeedResponse;
    await this.authApi.post<ReadRequest, NeedResponseNt>(`${this.base}`, {count: count, searchKey: value, skip: skip})
      .then((w) => {
        needResponse = NeedManagementApi.mapToNeedResponse(w)
      });
    return needResponse;
  }

  public async getAll() {
    let needResponse: NeedResponse;
    await this.authApi.post<ReadRequest, NeedResponseNt>(`${this.base}`, {count: 100000, searchKey: null, skip: 0})
      .then((w) => {
        needResponse = NeedManagementApi.mapToNeedResponse(w)
      });
    return needResponse;
  }

  public async upsert(needDto: NeedDto) {
    let needResponse: NeedResponse;
    await this.authApi.put<NeedDto, NeedResponseNt>(this.base, needDto)
      .then((w) => {
        needResponse = NeedManagementApi.mapToNeedResponse(w)
      })
    return needResponse;
  }

  public async connectVehicleToNeed(request: NeedVehicleRequest) {
    let needResponse: NeedResponse;
    await this.authApi.post<NeedVehicleRequest, NeedResponseNt>(this.base + "/vehicle", request)
      .then((w) => {
        needResponse = NeedManagementApi.mapToNeedResponse(w)
      })
    return needResponse;
  }

  public async connectHumanToNeed(request: NeedHumanRequest) {
    let needResponse: NeedResponse;
    await this.authApi.post<NeedHumanRequest, NeedResponseNt>(this.base + "/human", request)
      .then((w) => {
        needResponse = NeedManagementApi.mapToNeedResponse(w)
      })
    return needResponse;
  }

  public async delete(id: number) {
    let needResponse: NeedResponse;
    await this.authApi.delete<NeedDto, NeedResponseNt>(`${this.base}/${id}`)
      .then((w) => {
        needResponse = NeedManagementApi.mapToNeedResponse(w)
      });
    return needResponse;
  }

  private static mapToNeedResponse(needResponseNt: NeedResponseNt) {
    return new NeedResponse(needResponseNt);
  }
}
