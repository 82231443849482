import { useEffect, useState } from "react";
import { WorkerDto } from "../../../api/entity/WorkerDto";
import React from "react";
import "./SideBar.scss";
import { debounce, min, transform } from "lodash";
import WorkerCard from "../WorkerCard/WorkerCard";
import { getFilteredWorkers, IWorkers, splitWorkersByServices } from "../DailyOverviewUtils";
import { useDroppable } from "@dnd-kit/core";

interface IProps {
  containerVehicles: any[];
  dailyWorkers: any[];
  dailyVehicles: any[];
  vehiclesMap: Map<number, any>,
  radioCodeMap: Map<number, string>,
  workersMap: Map<string, WorkerDto>,
  shiftMap: Map<string, any>,
  dragging: boolean;
  workersByService: IWorkers;
  circuitFilter: string;
  reducedHeight: boolean;
}

const TABS = ["REPERIBILE", "MALATTIA", "RIPOSO", "SMONTO", "FERIE", "EXTRA", "GESTIONE", "ELIMINA"];
const TABS_TITLES = ["REPERIBILI", "MALATTIE", "RIPOSO", "SMONTO", "FERIE", "EXTRA", "GESTIONE", "ELIMINA"];
const TAB_REDUCED_TITLES = ["REP", "MAL", "RIP", "SMO", "FER", "EXT", "GES", "ELI"];

export default function SideBar({
  containerVehicles: containerVehicles,
  dailyWorkers,
  dailyVehicles,
  vehiclesMap,
  radioCodeMap,
  workersMap,
  shiftMap,
  dragging = false,
  circuitFilter,
  reducedHeight = false,
}: IProps) {

  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS[0]);

  const tabsDroppable = TABS.map((tab) => {
    const  { isOver, setNodeRef } = useDroppable({
      id: tab + "|SERVICE",
    });

    return { tab, isOver, setNodeRef };
  });

  var tabsDroppableMap = {};
  tabsDroppable.forEach((tab) => {
    tabsDroppableMap[tab.tab] = tab;
  });

  var considerSidebarOpened = sideBarOpened && !dragging;

  useEffect(() => {
    if (dragging) {
      setSideBarOpened(false);
    }
  });

  var filteredWorkers = getFilteredWorkers( dailyWorkers, workersMap, circuitFilter );

  const tabWorkers = splitWorkersByServices(filteredWorkers);

  const DISPLAY_TABS = TABS.filter(x => x != "ELIMINA");


  var debounceSetSidebar = debounce((open) => {
    setSideBarOpened(open);
  }, 100);

  useEffect(() => {
    //listen to onmouseover and onmouseout events
    var element = document.querySelector("div#sidebar");
    var tabsElement = document.querySelector("div.tabs");

    var tabsMouseOverFunctions = TABS.map((tab) => {
      return () => {
        if (!dragging) {
          //setSelectedTab(tab);
          //debounceSetSidebar(true);
        }
      };
    });

    function handleMouseOver(event) {
      if (!sideBarOpened) {
        console.log("Mouse over");
        //debounceSetSidebar(true);
      }
    }
    function handleMouseOut(event)  {
      //only if out from the right side
      var elementrect = element.getBoundingClientRect();
      var x = event.clientX;
      var y = event.clientY;
      if (x < elementrect.x || y > elementrect.y + elementrect.height) {
        console.log("Mouse out");
        //debounceSetSidebar(false);
      }
    }

    tabsElement.addEventListener("mouseover", handleMouseOver);
    element.addEventListener("mouseout", handleMouseOut);

    DISPLAY_TABS.forEach((tab) => {
      var tabElement = document.querySelector("div#tab"+tab);
      tabElement.addEventListener("mouseover", tabsMouseOverFunctions[TABS.indexOf(tab)]);
    });

    return () => {
      tabsElement.removeEventListener("mouseover", handleMouseOver);
      element.removeEventListener("mouseout", handleMouseOut);

      DISPLAY_TABS.forEach((tab) => {
        var tabElement = document.querySelector("div#tab"+tab);
        tabElement?.removeEventListener("mouseover", tabsMouseOverFunctions[TABS.indexOf(tab)]);
      });
    };
  })




  const hideTranslation = 1000;
  const sidebarTranslation = considerSidebarOpened ? "translateX(0px)": "translateX("+hideTranslation+"px)";
  const tabsTranslation = considerSidebarOpened ? "translateX(0px)": "translateX(-"+hideTranslation+"px)";
  let classNameSelectedTab = "selected-tab";

  if (considerSidebarOpened) {
    classNameSelectedTab += " visible";
  }

  // const extraWorkerDragTranslation = dragging ? {
  //   x: -hideTranslation,
  //   y: 0,
  //   scaleX: 0, scaleY: 0 } : { x: 0, y: 0, scaleX: 1, scaleY: 1 };


  const sidebarStyle = {
    transform: sidebarTranslation,
  }

  const tabsStyle = {
    transform: tabsTranslation,
  }

  const tabTitles = reducedHeight ? TAB_REDUCED_TITLES : TABS_TITLES;
  const lastTabTitle = tabTitles[tabTitles.length - 1];

  const sidebarClass = "side-bar" + (reducedHeight? " reduced": "");

  return <div id="sidebar" className={sidebarClass}  style={sidebarStyle}>
    <div className={classNameSelectedTab}>
        <div className="tabHeader">
          <div className="label">{selectedTab}</div>
          <div className="close" onClick={ () => setSideBarOpened(false) }>X</div>
        </div>

        {tabWorkers[selectedTab] && <div className="workersSlot">
            {tabWorkers[selectedTab].map((worker, index) => {
              return  <WorkerCard key={index} worker={ {...worker, suffix: "|"+selectedTab, dto: workersMap.get(worker.workerid) }} /* extraDragTransform={extraWorkerDragTranslation} */ />;
            })}
          </div>}
    </div>
    <div className={ "tabs " } style={tabsStyle} >
      {DISPLAY_TABS.map((tab, idx) => {
        var warning = tabWorkers[tab]?.length > 0 && tab == "GESTIONE";
        return (<div id={ "tab"+tab }
            ref={tabsDroppableMap[tab].setNodeRef}
            key={tab}
            className={ (warning?"warningtab ":"") + "tab" + ((selectedTab==tab && sideBarOpened)? " selected": "") + (tabsDroppableMap[tab].isOver? " dropHighlight":"") }
            onClick={ ( () => {
              if (selectedTab == tab) {
                debounceSetSidebar(!sideBarOpened);
              }
              else {
                setSelectedTab(tab);
                debounceSetSidebar(true);
              }
        }) }>
          <span className='label'>{tabTitles[idx]}</span>
          <div className='count'><span>{tabWorkers[tab]?.length ?? 0}</span></div>
        </div>); })}

        {dragging && <div
          ref={tabsDroppableMap['ELIMINA'].setNodeRef}
          className={ "elimina tab" + (selectedTab=='ELIMINA'? " selected": "") + (tabsDroppableMap['ELIMINA'].isOver? " dropHighlight":"") }>
          {/* recycle bin icon */}
          <span className='icon'>

          </span>

          <span className='label'>{lastTabTitle}</span>
          <div className='count'><img className="trash" src={"assets/trash.svg"}/></div>

        </div>}
    </div>
    </div>;
}
