import TimeLine from "../../../components/TimeLine/TimeLine";
import React, {Component, ReactNode} from "react";
import {DateTime} from "luxon";
import Time from "../../../lib/utils/Time";
import VehicleHeader from "./DailyVehicleHeader/VehicleHeader";
import TimeLineData from "../../../components/TimeLine/TimeLineData";
import NeedUtils from "../../../api/need/NeedUtils";
import DailyVehicleCell from "./DailyVehicleCell/DailyVehicleCell";
import {Vehicle} from "../../VehicleManagement/MonthlyVehicleTimeLine/MonthlyVehicleTimeLine";
import {VehicleDto} from "../../../api/entity/VehicleDto";
import {VehicleUnavailabilityDto} from "../../../api/entity/VehicleUnavailabilityDto";
import {VehicleDailyPlanningDto} from "../../../api/entity/VehicleDailyPlanningDto";
import DailyVehicleLine from "./DailyVehicleLine/DailyVehicleLine";
import Range from "../../../utils/Range";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import I18n from "../../../lib/I18n";
import {StringUtils} from "../../../utils/StringUtils";
import VehicleManagementUtils from "../../VehicleManagement/VehicleManagementUtils";
import {CircuitSelect} from "../../../components/CircuitSelect/CircuitSelect";
import {NeedVehicleDto} from "../../../api/entity/NeedVehicleDto";
import TimeLineMatrix = TimeLineData.TimeLineMatrix;
import Interval = TimeLineData.Interval;

interface Props {
  filter: string,
  day: DateTime,
  circuitFilter: string,
  radioMap: Map<number, string>,
  vehicleNeeds: Map<number, NeedVehicleDto>,
  allVehicles: VehicleDto[],
  dailyPlanningVehicles: VehicleDailyPlanningDto[],
  onVehicleIdLoad: (id: number) => void;
  onVehicleSelection: (vehicle: Vehicle) => void;
  onToggleDeleteConfirmModal: (v: VehicleUnavailabilityDto) => void;
}

interface State {
  filteredTimeLineMatrix: TimeLineMatrix[]
}

export default class VehiclesTimeLine extends Component<Props, State> {
  /**************************************************
   * CONSTRUCTOR
   **************************************************/
  constructor(props) {
    super(props);
    this.state = {
      filteredTimeLineMatrix: []
    }
  }

  componentDidMount() {
    this.loadDailyTimeLines();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.filter != this.props.filter ||
      prevProps.dailyPlanningVehicles != this.props.dailyPlanningVehicles ||
      this.props.day != prevProps.day ||
      this.props.circuitFilter != prevProps.circuitFilter) {
      this.loadDailyTimeLines();
    }
  }

  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    return <TimeLine
      headers={this.vehicleHeaders(this.props.day)}
      timeLines={this.state.filteredTimeLineMatrix}
    />
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private vehicleHeaders(currentDate: DateTime): ReactNode[] {
    const headers: ReactNode[] = []
    const hours = Time.getHoursInDay()
    for (const hour of hours)
      headers.push(<VehicleHeader day={currentDate.toJSDate()} hour={hour}/>)
    return headers
  }

  private loadDailyTimeLines(): void {
    const timeLines: (TimeLineMatrix & { service: string })[] = [];
    let vehicles = VehiclesTimeLine.mapVehicles(this.props.allVehicles);

    vehicles = this.filterOnFilteredSearch(vehicles);

    vehicles = vehicles.sort(this.byCategoryAndName)

    for (let i = 0; i < vehicles.length; i++) {
      let vehicle = vehicles[i];
      const timelineIntervals: Interval[] = []
      let service = ""

      // const vuDto = this.props.vehicleUnavailabilities.filter(vu => vu.vehicleId == vehicle.id);
      const electedEntries = this.props.dailyPlanningVehicles.filter(vu => vu.vehicleId == vehicle.id && this.isEntryRelevantForDay(vu, this.props.day));

      // vuDto.forEach(vu => timelineIntervals.push(this.getTimeLineForUnavailability(vu)));
      electedEntries.forEach(vE => timelineIntervals.push(this.getTimeLineForEntry(vE)))

      timeLines.push({
        backgroundColor: "#f3f6f8",
        service,
        intervals: timelineIntervals,
        title: <DailyVehicleCell
          onClick={(vehicle) =>
            this.props.onVehicleSelection(vehicle)}
          vehicle={vehicle} type={vehicle.category}
          id={i.toString()}
        />
      })
    }
    this.setState({filteredTimeLineMatrix: this.sortByServiceType(timeLines)});
  }

  private byCategoryAndName(a: Vehicle, b: Vehicle): number {
    if (a.category != b.category) {
      if (a.category == "EM+") return -1
      else if (b.category == "EM+") return 1
      else if (a.category < b.category) return -1;
      else if (a.category > b.category) return 1;
    } else {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      else return 0;
    }
  }

  private isEntryRelevantForDay(vu: VehicleDailyPlanningDto, day: DateTime) {
    const entryDate = DateTime.fromFormat(vu.date, GfDateUtils.STORED_DATE_FORMAT).startOf('day');
    const daysDiff = day.startOf('day').diff(entryDate, 'day').days;
    const ONE_DAY = 1;
    if (daysDiff > ONE_DAY) {
      return false;
    }
    if (daysDiff == ONE_DAY) {
      return GfDateUtils.convertHHMMToNumber(vu.vehicleEndTime) < GfDateUtils.convertHHMMToNumber(vu.vehicleStartTime);
    }
    return true;
  }

  public static mapVehicles(vehiclesDtos: VehicleDto[]) {
    let vehicles: Vehicle[] = []
    for (const vehicle of vehiclesDtos) {
      vehicles.push({
        id: vehicle.id,
        name: vehicle.name,
        plate: vehicle.plate,
        circuit: vehicle.circuit,
        category: vehicle.category
      })
    }
    return vehicles;
  }

  private filterOnFilteredSearch(vehicles: Vehicle[]) {
    if (this.props.filter != "") {
      const searchString = this.props.filter.toLowerCase();
      vehicles = vehicles.filter(v =>
        StringUtils.contains(v.name.toLowerCase(), searchString) ||
        StringUtils.contains(v.category.toLowerCase(), searchString));
    }
    const circuitFilter = this.props.circuitFilter;
    vehicles = vehicles
      .filter(v => circuitFilter == CircuitSelect.NO_FILTER ||
        v.circuit.toLowerCase() == circuitFilter.toLowerCase())
    return vehicles;
  }

  private getTimeLineForEntry(ve: VehicleDailyPlanningDto): Interval {
    const range = new Range(ve.vehicleStartTime, ve.vehicleEndTime);

    return {
      color: VehicleManagementUtils.getTimelineBgColorDailyPlanningVehicle(ve),
      textColor: VehicleManagementUtils.getTimelineTextColorDailyPlanningVehicle(ve),
      start: range.boundStart,
      end: range.boundEnd,
      content: <DailyVehicleLine
        className={ve.previousDay ? "" : "actionable"}
        onClick={() => {
          if (!ve.previousDay) {
            var vehicle = this.props.allVehicles.find(v => v.id == ve.vehicleId);
            this.props.onVehicleSelection(vehicle);
          }
        }}
        id={ve.id}
        textColor={VehicleManagementUtils.getTimelineTextColorDailyPlanningVehicle(ve)}
        name={this.getTimeLineName(ve)}
        start={range.startString}
        end={range.endString}
      />
    }
  }

  private getTimeLineName(ve: VehicleDailyPlanningDto) {
    switch (ve.containerVehicle) {
      case "OFF":
        return I18n.get().VehicleUnavailabilityModal.reasons.maintenance.toUpperCase();
      case "LAV":
        return I18n.get().VehicleUnavailabilityModal.reasons.washing.toUpperCase();
      default:
        const containerVehicle = ve.containerVehicle;
        const placement = VehiclesTimeLine.getPlacement(containerVehicle, this.props.vehicleNeeds);
        if (ve.radiocodeid) return this.props.radioMap.get(ve.radiocodeid) + " " + placement;
        return ve.containerVehicle.toUpperCase() + " " + placement;
    }
  }

  private sortByServiceType(timeLines: (TimeLineMatrix & { service: string })[]):
    TimeLineMatrix[] {
    const free = this.filterTimeLinesBy(timeLines, NeedUtils.isFree);
    const available = this.filterTimeLinesBy(timeLines, NeedUtils.isAvailable);
    const vacation = this.filterTimeLinesBy(timeLines, NeedUtils.isVacation);
    const rest = this.filterTimeLinesBy(timeLines, NeedUtils.isRest);
    const out_of_turn = this.filterTimeLinesBy(timeLines, NeedUtils.isOutOfTurn);
    const unmount = this.filterTimeLinesBy(timeLines, NeedUtils.isUnmount);
    const other = this.filterTimeLinesBy(timeLines, NeedUtils.isOther);
    return free
      .concat(available)
      .concat(vacation)
      .concat(rest)
      .concat(other)
      .concat(out_of_turn)
      .concat(unmount);
  }

  private filterTimeLinesBy(timeLines: (TimeLineMatrix & { service: string })[], comparatorFn: (service: string) => boolean):
    TimeLineMatrix[] {
    const filtered = []
    for (const t of timeLines) {
      if (comparatorFn(t?.service)) {
        const {service, ...timeLine} = t;
        filtered.push(timeLine)
      }
    }
    return filtered;
  }

  public static getPlacement(containerVehicle: string, needVehicleMap: Map<number, NeedVehicleDto>): string {
    if (containerVehicle) {
      const splits = containerVehicle.split("_");
      if (splits.length == 3) {
        const vehicleNeedId = splits[1];
        const key = parseInt(vehicleNeedId);
        return needVehicleMap.get(key).parkingDescription;
      }
    }
    return "";
  }
}
