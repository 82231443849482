import React, { Component } from 'react';
import './AddWorkerServiceModal.scss';
import I18n from '../../../lib/I18n';
import Button from '../../Button/Button';
import { DateTime } from 'luxon';
import Select from 'react-select';
import { VehicleDailyPlanningDto } from '../../../api/entity/VehicleDailyPlanningDto';
import { Vehicle } from '../../../pages/VehicleManagement/MonthlyVehicleTimeLine/MonthlyVehicleTimeLine';
import TimeInputPicker from '../../TimeInputPicker/TimeInputPicker';
import GfDateUtils from '../../DatePicker/GfDateUtils';
import { DailyContainerVehicle } from '../../../api/entity/response/nt/ContainerVehicleResponseNt';
import { WorkerServiceTurnModal } from '../WorkerServiceTurnModal/WorkerServiceTurnModal';
import { NeedVehicleDto } from '../../../api/entity/NeedVehicleDto';
import { VehicleServiceTurnModalError, VehicleServiceTurnUtils } from '../VehicleServiceTurnModal/VehicleServiceTurnUtils';
import { getServiceToRecord, getWorkerStartEndForShiftInContainerVehicle, normalizeTime } from '../../../pages/DailyOverview/DailyOverviewUtils';
import { VehiclePlanning } from '../../../languages/languages';
import { VehiclePlanningDto } from '../../../api/entity/VehiclePlanningDto';
import DailyWorkerDTO from '../../../pages/DailyPlanning/DailyWorkersTimeLine/DailyWorkerDTO';
import { DailyPlanningWorkerDto } from '../../../api/entity/response/nt/DailyPlanningWorkerResponseNt';
import { WorkerDto } from '../../../api/entity/WorkerDto';
import { ShiftDto } from '../../../api/entity/ShiftDto';

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  date: DateTime;
  workers: WorkerDto[];
  shifts: ShiftDto[];
  containerVehicle: DailyContainerVehicle;
  onSave: (create: DailyPlanningWorkerDto) => void;
  onCancel: () => void;
}

interface State {
  selectedWorker: WorkerDto;
  selectedShift: ShiftDto;
  create: DailyPlanningWorkerDto;
}


export class AddWorkerServiceModal extends Component<Props, State> {

  constructor(props) {
    super(props);
    var startTime = normalizeTime(props.containerVehicle.startshift);
    var endTime = normalizeTime(props.containerVehicle.endshift);


    this.state = {
      selectedWorker: null,
      selectedShift: null,
      create:
        {
          id: null,
          date: props.containerVehicle.date,
          companionid: 0,
          workerid: 0,
          workername: "",
          containervehicle: props.containerVehicle.containervehicle,
          shiftstart: "", //newWorker.shiftstart,
          shiftend: "", //newWorker.shiftend,
          servicestart: "",// props.containervehicle.servicestart
          serviceend: "",//newWorker.serviceend,
          service: "",//newWorker.service,
          turn: "", //newWorker.turn,
          vehicle: "", //newWorker.vehicle,
          servicecategory: props.containerVehicle.category,
          manuallyCreated: true,
          radiocodeid: props.containerVehicle.radiocodeid ?? 0,
          plannedTurn: null,
        }
      };
  }



  async componentDidMount() {}



  private triggerClose() {
    this.props.onCancel();
  }

  private getLabel(worker: WorkerDto) {
    if (!worker) return '';
    return `${worker.lastname} - ${worker.name}`;
  }

  // private getLabel(radioCode: string, containerVehicle: string) {
  //   const splitContainerVehicle = containerVehicle.split("_");
  //   if (splitContainerVehicle.length > 2) {
  //     const vehicleId = parseInt(splitContainerVehicle[1]);
  //     const vehicleNumber = parseInt(splitContainerVehicle[2]);

  //     if (isNaN(vehicleId)) throw new Error();
  //     if (isNaN(vehicleNumber)) throw new Error();

  //     const containerName = radioCode ? radioCode : containerVehicle;
  //     const electedVehicle = this.props.vehicleNeedMap.get(vehicleId);
  //     if (electedVehicle) {
  //       const category = electedVehicle.category;
  //       const timeLabel = `${electedVehicle.fromTime}-${electedVehicle.toTime}`;
  //       return `${containerName} ${category} ${timeLabel} (${vehicleNumber})`;
  //     }
  //     return containerVehicle;
  //   }
  //   return containerVehicle;
  // }

  // private getNeed(containerVehicle: string) : {start: string, end: string} {
  //   const splitContainerVehicle = containerVehicle.split("_");
  //   if (splitContainerVehicle.length > 2) {
  //     const vehicleId = parseInt(splitContainerVehicle[1]);
  //     const electedVehicle = this.props.vehicleNeedMap.get(vehicleId);
  //     if (electedVehicle) {
  //       return {start: electedVehicle.fromTime, end: electedVehicle.toTime};
  //     }
  //   }
  //   return {start: "", end: ""};
  // }

  private onSave() {
    if (!this.state.selectedWorker) {
      return;
    }

    if (!this.state.selectedShift) {
      return;
    }

    let { create } = this.state;

    create.workerid = this.state.selectedWorker.id;
    create.containervehicle = this.props.containerVehicle.containervehicle;
    create.radiocodeid = this.props.containerVehicle.radiocodeid ?? 0;
    create.service = this.props.containerVehicle.service;


    var [start, end] = this.getWorkerStartEnd(this.state.selectedShift, this.props.containerVehicle);
    create.shiftstart = start;
    create.shiftend = end;
    create.servicestart = this.props.containerVehicle.startshift;
    create.serviceend = this.props.containerVehicle.endshift;

    create.turn = this.state.selectedShift.shiftCode;
    create.companionid = 0; //???

    create.manuallyCreated = true;
    create.servicecategory = this.props.containerVehicle.category;

    create.service = getServiceToRecord(create.service, create.turn);


    this.props.onSave(create);
  }

  getWorkerStartEnd(shiftObj: ShiftDto, containerObj: DailyContainerVehicle) {

    if (!shiftObj) {
      return [ "00:00", "23:59" ];
    }
    var [start, end] = getWorkerStartEndForShiftInContainerVehicle(shiftObj, containerObj, true);

    return [start, end];
  }

  private getWorkers() {
    return Array.from(
      new Set(
        this.props.workers.map(s => {
          return {
            label: this.getLabel(s),
            value: s.id,
          };
        }),
      ),
    );
    return [];
  }

  private getShifts() {
    return Array.from(
      new Set(
        this.props.shifts.map(s => {
          return {
            label: s.shiftCode,
            value: s.id,
          };
        }),
      ),
    );
    return [];
  }

  private changeWorker(worker: SelectOption) {
    var selectedWorker = this.props.workers.find(w => w.id?.toString() == worker.value);
    this.setState({
      selectedWorker
    });
  }

  private changeShift(shift: SelectOption) {
    var selectedShift = this.props.shifts.find(s => s.id?.toString() == shift.value);
    this.setState({
      selectedShift
    });
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className="AddWorkerServiceModal-Overlay">
        <div className="AddWorkerServiceModal">
          <h2>{I18n.get().RadioCodeRegistry.table.containerVehicle + ": " + this.props.containerVehicle.containervehicle}</h2>
          <div className="AddWorkerServiceModal-Body">
            <div className={'row'}>
              <div className={'w-h-g w-h'}>
                <label className={'TextInput-label full'}>{I18n.get().TotemWorkers.table.operator}</label>
                <Select
                  className={'row-w height'}
                  onChange={worker => this.changeWorker(worker)}
                  options={this.getWorkers()}
                  value={{
                    label: this.getLabel(this.state.selectedWorker),
                    value: this.state.selectedWorker?.id,
                  }}
                />
              </div>
              <div className={"w-h"}>
                <label className={"TextInput-label full"}>{I18n.get().TotemWorkers.table.shift}</label>

                <Select className={"row-w height"}
                        onChange={shiftValue => this.changeShift(shiftValue)}
                        options={this.getShifts()}
                        value={{value: this.state.selectedShift?.id , label: this.state.selectedShift?.shiftCode}}
                />

              </div>
            </div>
          </div>

          <div className="AddWorkerServiceModal-Buttons">
            <Button onClick={() => this.onSave()} className="WorkerServiceTurnModal-OkButton">
              Ok
            </Button>
            <Button onClick={() => this.triggerClose()} secondary={true} className="ConfirmModal-CancelButton">
              {I18n.get().MonthlyPlanning.cancelModal}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
