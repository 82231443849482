import {ShiftDto} from "../../entity/ShiftDto";
import {ShiftModalErrors} from "../../../components/Modal/ShiftModal/ShiftModal";
import I18n from "../../../lib/I18n";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import ShiftRegistryApi from "../../shift/ShiftRegistryApi";
import {ModalType} from "../../../components/Modal/ModalType";

export default class ShiftValidator {


  private static regexTime = "^[0-9]{2}:[0-9]{2}$";

  private static readonly MAX_LENGTH = 20;
  private static readonly MIN_LENGTH = 0;

  public static async validate(shiftDto: ShiftDto,
                               modalType: ModalType): Promise<ShiftModalErrors> {
    let shiftCodeLength = shiftDto.shiftCode.length;
    let shiftModErrors: ShiftModalErrors = {
      codeTurn: "", typology: "",
      fromTime: "", toTime: "", time: "", pause: "",
      smontoH: "", extraH: "", applyExtraAtStart: ""
    };

    const shiftApi = new ShiftRegistryApi();

    if (shiftCodeLength == this.MIN_LENGTH || shiftCodeLength > this.MAX_LENGTH) {
      shiftModErrors.codeTurn = I18n.get().ShiftRegistry.shiftModal.codeLengthError;
    }

    if(modalType == ModalType.CREATE && (await shiftApi.getByCode(shiftDto.shiftCode)).content.length == 1) {
      shiftModErrors.codeTurn = I18n.get().ShiftRegistry.shiftModal.shiftCodeExistsError;
    }

    if (!new RegExp(this.regexTime).test(shiftDto.fromTime)
      || GfDateUtils.convertHHMMToNumber(shiftDto.fromTime) > GfDateUtils.timeBoundaryAsInt) {
      shiftModErrors.fromTime = I18n.get().ShiftRegistry.shiftModal.timeError;
    }

    if (!new RegExp(this.regexTime).test(shiftDto.pause)) {
      shiftModErrors.pause = I18n.get().ShiftRegistry.shiftModal.timeError;
    }

    if (!new RegExp(this.regexTime).test(shiftDto.toTime)
      || GfDateUtils.convertHHMMToNumber(shiftDto.toTime) > GfDateUtils.timeBoundaryAsInt) {
      shiftModErrors.toTime = I18n.get().ShiftRegistry.shiftModal.timeError;
    }
    if (!new RegExp(this.regexTime).test(shiftDto.time) ||
      GfDateUtils.convertHHMMToNumber(shiftDto.time) > GfDateUtils.timeBoundaryAsInt) {
      shiftModErrors.time = I18n.get().ShiftRegistry.shiftModal.timeError;
    }
    return shiftModErrors;
  }

}
