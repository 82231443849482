import { DndContext, DragCancelEvent, DragEndEvent, DragMoveEvent, DragOverEvent, DragStartEvent, MeasuringConfiguration, MeasuringStrategy, MouseSensor, TouchSensor, useSensor } from "@dnd-kit/core";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import React from "react";
import "./DailyDndContext.scss";

interface IProps {
  children: React.ReactNode;
  onDragStart?(event: DragStartEvent): void;
  onDragMove?(event: DragMoveEvent): void;
  onDragOver?(event: DragOverEvent): void;
  onDragEnd?(event: DragEndEvent): void;
  onDragCancel?(event: DragCancelEvent): void;
}


export default function DailyDndContext({
  children,
  onDragStart,
  onDragMove,
  onDragOver,
  onDragEnd,
  onDragCancel,

}: IProps) {

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  })

  const measuring: MeasuringConfiguration = {
      // draggable: {
      //   measure: (element) => {
      //     console.log('measure', element);

      //     return {
      //       ...getClientRect(element, { ignoreTransform: false }),
      //       //top: 0 // getClientRect(element, { ignoreTransform: false }).top
      //      //left: element.getClientRects()[0].left
      //     };
      //   }
      // },
      droppable: {
        strategy: MeasuringStrategy.BeforeDragging,
        //frequency: 100000,
      },
    };

  return (
      <DndContext
          measuring={measuring}
          //collisionDetection={pointerWithin}
          sensors={[mouseSensor, touchSensor]}
          autoScroll={true}
          modifiers={[
            //restrictToWindowEdges,
            snapCenterToCursor,
          ]}
          onDragStart={onDragStart}
          onDragMove={onDragMove}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          {children}
        </DndContext>

  );
}
