import ShiftDtoNT from "./nt/ShiftDtoNT";

export class ShiftDto {

  public static separatorTypology = ";";

  id: number;
  shiftCode: string;
  typology: string[];
  shiftType: string;
  fromTime: string;
  toTime: string;
  time: string;
  pause: string;
  description: string;
  smontoH: string;
  autoGenerated:boolean;
  archived:boolean;
  extraH: string;
  applyExtraAtStart: boolean;

  constructor(dtoNt: ShiftDtoNT) {
    this.id = dtoNt.id;
    this.shiftCode = dtoNt.shiftCode;
    this.typology = dtoNt.typology;
    this.shiftType = dtoNt.shiftType;
    this.fromTime = dtoNt.fromTime;
    this.toTime = dtoNt.toTime;
    this.time = dtoNt.time;
    this.description = dtoNt.description;
    this.smontoH = dtoNt.smontoH;
    this.pause = dtoNt.pause;
    this.autoGenerated = dtoNt.autoGenerated;
    this.archived = dtoNt.archived;
    this.extraH = dtoNt.extraH;
    this.applyExtraAtStart = dtoNt.applyExtraAtStart;
  }
}
