import React, { Component } from 'react';
import './AddVehicleServiceModal.scss';
import I18n from '../../../lib/I18n';
import Button from '../../Button/Button';
import { DateTime } from 'luxon';
import Select from 'react-select';
import { VehicleDailyPlanningDto } from '../../../api/entity/VehicleDailyPlanningDto';
import { Vehicle } from '../../../pages/VehicleManagement/MonthlyVehicleTimeLine/MonthlyVehicleTimeLine';
import TimeInputPicker from '../../TimeInputPicker/TimeInputPicker';
import GfDateUtils from '../../DatePicker/GfDateUtils';
import { DailyContainerVehicle } from '../../../api/entity/response/nt/ContainerVehicleResponseNt';
import { WorkerServiceTurnModal } from '../WorkerServiceTurnModal/WorkerServiceTurnModal';
import { NeedVehicleDto } from '../../../api/entity/NeedVehicleDto';
import { VehicleServiceTurnModalError, VehicleServiceTurnUtils } from '../VehicleServiceTurnModal/VehicleServiceTurnUtils';
import { normalizeTime } from '../../../pages/DailyOverview/DailyOverviewUtils';
import { VehiclePlanning } from '../../../languages/languages';
import { VehiclePlanningDto } from '../../../api/entity/VehiclePlanningDto';

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  date: DateTime;
  vehicles: Vehicle[];
  containerVehicle: DailyContainerVehicle;
  onSave: (create: VehicleDailyPlanningDto) => void;
  onCancel: () => void;
}

interface State {
  selectedVehicle: Vehicle;
  fromTime: string;
  toTime: string;
  error: VehicleServiceTurnModalError;
  create: VehicleDailyPlanningDto;
}

export class AddVehicleServiceModal extends Component<Props, State> {

  constructor(props) {
    super(props);
    var startTime = normalizeTime(props.containerVehicle.startshift);
    var endTime = normalizeTime(props.containerVehicle.endshift);


    this.state = {
      error: null,
      fromTime: startTime,
      toTime: endTime,
      selectedVehicle: null,
      create: VehicleDailyPlanningDto.getEmpty(
        this.props.date.toFormat(GfDateUtils.STORED_DATE_FORMAT),
        0,
        startTime,
        endTime,
        this.props.containerVehicle.containervehicle,
        this.props.containerVehicle.radiocodeid,
      ),
    };
  }



  async componentDidMount() {}

  private async changeVehicle(selectOption: SelectOption) {
    var vehicle = this.props.vehicles.filter(v => v?.id?.toString() == selectOption.value)[0];
    this.setState({ selectedVehicle: vehicle });
  }

  private async changeFromTime(value: string) {
    let { error } = this.state;
    error = error
      ? error
      : {
          fromTime: null,
          toTime: null,
        };
    error.fromTime = null;
    this.setState({ fromTime: value, error });
  }

  private async changeToTime(value: string) {
    let { error } = this.state;
    error = error
      ? error
      : {
          fromTime: null,
          toTime: null,
        };
    error.toTime = null;
    this.setState({ toTime: value, error });
  }

  private triggerClose() {
    this.props.onCancel();
  }

  private getLabel(vehicle: Vehicle) {
    if (!vehicle) return '';
    return `${vehicle.name} - ${vehicle.category}`;
  }

  // private getLabel(radioCode: string, containerVehicle: string) {
  //   const splitContainerVehicle = containerVehicle.split("_");
  //   if (splitContainerVehicle.length > 2) {
  //     const vehicleId = parseInt(splitContainerVehicle[1]);
  //     const vehicleNumber = parseInt(splitContainerVehicle[2]);

  //     if (isNaN(vehicleId)) throw new Error();
  //     if (isNaN(vehicleNumber)) throw new Error();

  //     const containerName = radioCode ? radioCode : containerVehicle;
  //     const electedVehicle = this.props.vehicleNeedMap.get(vehicleId);
  //     if (electedVehicle) {
  //       const category = electedVehicle.category;
  //       const timeLabel = `${electedVehicle.fromTime}-${electedVehicle.toTime}`;
  //       return `${containerName} ${category} ${timeLabel} (${vehicleNumber})`;
  //     }
  //     return containerVehicle;
  //   }
  //   return containerVehicle;
  // }

  // private getNeed(containerVehicle: string) : {start: string, end: string} {
  //   const splitContainerVehicle = containerVehicle.split("_");
  //   if (splitContainerVehicle.length > 2) {
  //     const vehicleId = parseInt(splitContainerVehicle[1]);
  //     const electedVehicle = this.props.vehicleNeedMap.get(vehicleId);
  //     if (electedVehicle) {
  //       return {start: electedVehicle.fromTime, end: electedVehicle.toTime};
  //     }
  //   }
  //   return {start: "", end: ""};
  // }

  private onSave() {
    if (!this.state.selectedVehicle) {
      return;
    }

    if (this.state.error?.fromTime || this.state.error?.toTime) {
      return;
    }

    let { create } = this.state;

    create.vehicleStartTime = this.state.fromTime;
    create.vehicleEndTime = this.state.toTime;
    create.vehicleId = this.state.selectedVehicle.id;
    create.service = this.props.containerVehicle.containervehicle.split("_")[0];
    create.manuallyCreated = true;
    create.serviceCategory = this.state.selectedVehicle.category;
    create.manuallyCreated = true;
    create.serviceStartTime = this.state.fromTime;
    create.serviceEndTime = this.state.toTime;
    create.vehicleShortName = this.state.selectedVehicle.name;
    create.previousDay = false;
    create.id = null;
    this.props.onSave(create);
  }

  private getVehicles() {
    return Array.from(
      new Set(
        this.props.vehicles.map(s => {
          return {
            label: this.getLabel(s),
            value: s.id,
          };
        }),
      ),
    );
    return [];
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className="AddVehicleServiceModal-Overlay">
        <div className="AddVehicleServiceModal">
          <h2>{I18n.get().RadioCodeRegistry.table.containerVehicle + ": " + this.props.containerVehicle.containervehicle}</h2>
          <div className="AddVehicleServiceModal-Body">
            <div className={'row'}>
              <div className={'w-h-g w-h'}>
                <label className={'TextInput-label full'}>{I18n.get().TotemWorkers.table.vehicle}</label>
                <Select
                  className={'row-w height'}
                  onChange={vehicleId => this.changeVehicle(vehicleId)}
                  options={this.getVehicles()}
                  value={{
                    label: this.getLabel(this.state.selectedVehicle),
                    value: this.state.selectedVehicle?.id,
                  }}
                />
              </div>
              <div className={'w-h'}>
                <TimeInputPicker
                  onCorrectChange={time => {
                    this.changeFromTime(time);
                  }}
                  onError={e => {
                    let { error } = this.state;
                    error = error
                      ? error
                      : {
                          fromTime: null,
                          toTime: null,
                        };
                    error.fromTime = e;
                    this.setState({ error: error });
                  }}
                  label={I18n.get().VehicleUnavailabilityModal.from}
                  placeholder={'00:00'}
                  className={'row-w'}
                  defaultValue={this.state.fromTime}
                  error={this.state.error?.fromTime ? this.state.error.fromTime != '' : false}
                />
              </div>
              <div className={'w-h'}>
                <TimeInputPicker
                  onCorrectChange={time => {
                    this.changeToTime(time);
                  }}
                  onError={e => {
                    var { error } = this.state;
                    error = error
                      ? error
                      : {
                          fromTime: null,
                          toTime: null,
                        };
                    error.toTime = e;
                    this.setState({ error: error });
                  }}
                  label={I18n.get().VehicleUnavailabilityModal.to}
                  placeholder={'00:00'}
                  className={'row-w'}
                  defaultValue={this.state.toTime}
                  error={this.state.error?.toTime ? this.state.error.toTime != '' : false}
                />
              </div>
            </div>
          </div>

          <div className="VehicleServiceTurnModal-Buttons">
            <Button onClick={() => this.onSave()} className="WorkerServiceTurnModal-OkButton">
              Ok
            </Button>
            <Button onClick={() => this.triggerClose()} secondary={true} className="ConfirmModal-CancelButton">
              {I18n.get().MonthlyPlanning.cancelModal}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
