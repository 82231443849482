import "./DailyServiceLine.scss"
import React from "react";

interface Props{
  name:string
  start:string
  end:string,
  className?:string,
  onClick?:()=>void
}

export default function DailyServiceLine(props:Props){
  const {name, start, end} = props;
  const text = `${name} - ${start} - ${end}`
  return <div className={ "DailyServiceLine" + (props.className? (" "+props.className): "") } title={text} onClick={() => props.onClick()}>{text}</div>
}
