import React from 'react';
import './DailyPlanning.scss';
import Page from "../Page";
import DailyPlanningApi from "../../api/planning/DailyPlanningApi";
import {DateTime} from "luxon";
import {
  DailyPlanningWorkerDto,
  DailyPlanningWorkerDtoNt
} from "../../api/entity/response/nt/DailyPlanningWorkerResponseNt";
import {WorkerDto} from "../../api/entity/WorkerDto";
import WorkerRegistryApi from "../../api/worker/WorkerRegistryApi";
import DailyServicesTimeLineOverview from "./DailyServicesTimeLineOverview/DailyServicesTimeLineOverview";
import NeedManagementApi from "../../api/need/NeedManagementApi";
import {NeedDto} from "../../api/entity/NeedDto";
import I18n from "../../lib/I18n";
import DailyNavigationBar from "./DailyNavigationBar/DailyNavigationBar";
import DailyServicesTimeLineDetail from "./DailyServicesTimeLineDetail/DailyServicesTimeLineDetail";
import DailyWorkersTimeLine, {Worker} from "./DailyWorkersTimeLine/DailyWorkersTimeLine";
import {ShiftDto} from "../../api/entity/ShiftDto";
import ShiftRegistryApi from "../../api/shift/ShiftRegistryApi";
import {FocusPanel} from "../../components/FocusPanel/FocusPanel";
import {WorkerServiceTurnModal} from "../../components/Modal/WorkerServiceTurnModal/WorkerServiceTurnModal";
import {ConfirmModal} from "../../components/Modal/ConfirmModal/ConfirmModal";
import Button from "../../components/Button/Button";
import WorkersRegistryUtils from "../WorkersRegistry/WorkerUtils";
import SearchInput from "../../components/SearchInput/SearchInput";
import VehiclesTimeLine from "./DailyVehiclesTimeLine/VehiclesTimeLine";
import VehicleApi from "../../api/vehicle/VehicleApi";
import VehiclePlanningApi from "../../api/vehicle/VehiclePlanningApi";
import {VehicleDto} from "../../api/entity/VehicleDto";
import {VehicleDailyPlanningDto} from "../../api/entity/VehicleDailyPlanningDto";
import {VehicleServiceTurnModal} from "../../components/Modal/VehicleServiceTurnModal/VehicleServiceTurnModal";
import {Vehicle} from "../VehicleManagement/MonthlyVehicleTimeLine/MonthlyVehicleTimeLine";
import MonthlyPlanningApi from "../../api/planning/MonthlyPlanningApi";
import {MonthlyPlanningDto} from "../../api/entity/MonthlyPlanningDto";
import {StatusEnum} from "../../api/engine/EngineApi";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import InfoBar from "../../components/InfoBar/InfoBar";
import ContainerVehicleApi from "../../api/container-vehicle/ContainerVehicleApi";
import {DailyContainerVehicle} from "../../api/entity/response/nt/ContainerVehicleResponseNt";
import {RadioCodeDto} from "../../api/entity/RadioCodeDto";
import RadioCodeApi from "../../api/radio/RadioCodeApi";
import {NeedVehicleDto} from "../../api/entity/NeedVehicleDto";
import {CircuitSelect} from "../../components/CircuitSelect/CircuitSelect";
import Spinner from "../../components/Spinner/Spinner";
import { RouteComponentProps } from 'react-router-dom';
import { Pages } from '../../router/Pages';
import { AddVehicleServiceModal } from '../../components/Modal/AddVehicleServiceModal/AddVehicleServiceModal';
import { AddWorkerServiceModal } from '../../components/Modal/AddWorkerServiceModal/AddWorkerServiceModal';
import { delay } from 'lodash';

interface State {
  selectedView: SelectedView
  selectedService: string
  dailyWorkers: DailyPlanningWorkerDtoNt[]
  selectedDailyWorkers: DailyPlanningWorkerDto[]
  containerServiceVehicle: DailyContainerVehicle[]
  needs: NeedDto[]

  allVehicles: VehicleDto[],
  dailyVehicles: VehicleDailyPlanningDto[],

  dailyVehiclesStartingOnDate: VehicleDailyPlanningDto[],
  dailyWorkersStartingOnDate: DailyPlanningWorkerDtoNt[],
  dailyContainerVehicleStartingOnDate: DailyContainerVehicle[],
  needVehicles: NeedVehicleDto[]

  monthlyPlanning: MonthlyPlanningDto
  allWorkers: WorkerDto[]
  activeWorkers: WorkerDto[]
  radioCodeIds: RadioCodeDto[]
  radioCodeMap: Map<number, string>
  vehiclesMap: Map<number, NeedVehicleDto>
  shifts: ShiftDto[]
  day: DateTime,
  selectedWorker: Worker,
  selectedVehicle: Vehicle,
  workerForDeletion: Worker,
  filter: string;
  circuitFilter: string;
  isWorkerModalOpen: boolean;
  isVehicleModalOpen: boolean;
  isAddWorkerModalOpen: boolean;
  isWorkerModalCreateWorker: boolean;
  isWorkerModalConfirmOpen: boolean;
  circuitFilterDisabled: boolean;
  containerVehicleToAddTo: DailyContainerVehicle;
  isAddVehicleModalOpen: boolean;
}

export enum SelectedView {
  SERVICES = "SERVICES",
  WORKERS = "WORKERS",
  VEHICLES = "VEHICLES"
}

export default class DailyPlanning extends Page<{}, State> {

  private containerVehicleApi = new ContainerVehicleApi();
  private dailyPlanningApi = new DailyPlanningApi();
  private workerRegistryUtils = new WorkersRegistryUtils()
  private monthlyPlanningApi = new MonthlyPlanningApi();
  private radioCodeApi = new RadioCodeApi();
  private needApi = new NeedManagementApi();

  private vehicleApi = new VehicleApi();
  // private vehicleApiUnavailability = new VehicleUnavailabilityApi();
  private vehiclePlanningApi = new VehiclePlanningApi();


  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any) {
    this.ensureServiceSelection(prevState);
  }


  private ensureServiceSelection(prevState: Readonly<State>) {
    if (this.state.selectedService != null && prevState.selectedService != this.state.selectedService) {
      this.setState({circuitFilterDisabled: true});
    }
  }

  constructor(props) {
    super(props);
    var dayDate = DateTime.now();
    var selectedService: string = null;

    if (props as RouteComponentProps) {
      var dateParam = (props as RouteComponentProps).match.params["date"];
      if (dateParam) {
        var date = DateTime.fromFormat((props as RouteComponentProps).match.params["date"], GfDateUtils.STORED_DATE_FORMAT);
        if (date.isValid) {
          dayDate = date;
        }


        var serviceParam = (props as RouteComponentProps).match.params["service"];
        if (serviceParam) {
          selectedService = serviceParam
        }
      }
    }

    const day =dayDate.startOf("day");

    this.state = {
      selectedView: SelectedView.SERVICES,
      selectedService: null,
      dailyWorkers: null,
      activeWorkers: null,
      allWorkers: null,
      needs: null,
      shifts: null,
      selectedWorker: null,
      selectedVehicle: null,
      monthlyPlanning: null,
      dailyVehiclesStartingOnDate: [],
      dailyWorkersStartingOnDate: [],
      dailyContainerVehicleStartingOnDate: [],
      allVehicles: [],
      dailyVehicles: [],
      radioCodeIds: [],
      radioCodeMap: new Map(),
      vehiclesMap: new Map(),
      day,
      selectedDailyWorkers: [],
      containerServiceVehicle: [],
      isWorkerModalOpen: false,
      isVehicleModalOpen: false,
      workerForDeletion: null,
      isWorkerModalCreateWorker: false,
      isWorkerModalConfirmOpen: false,
      isAddVehicleModalOpen: false,
      isAddWorkerModalOpen: false,
      filter: "",
      circuitFilter: CircuitSelect.NO_FILTER,
      needVehicles: [],
      circuitFilterDisabled: false,
      containerVehicleToAddTo: null
    }
    this.loadDataForDay(day, selectedService);
    //uncomment to TEST delayed load on network throttling
    //  delay(() => {
      this.loadAllWorkers(() => {
        if (selectedService && !this.state.selectedService && this.state.allWorkers && this.state.allWorkers.length > 0) {
          this.loadDataForDay(day, selectedService);
        }
      });
    // }, 4000);
  }


  render() {

    const {
      day,
      selectedView,
      dailyWorkers,
      dailyVehiclesStartingOnDate,
      dailyWorkersStartingOnDate,
      dailyContainerVehicleStartingOnDate,
      needs,
      activeWorkers,
      allWorkers,
      allVehicles,
      shifts,
      selectedService,
      radioCodeMap
    } = this.state;

    if (!needs || !activeWorkers || !shifts)
      return <Spinner/>;

    const labels = I18n.get().DailyTimeLine;
    return (
      <div>
        <div className="DailyPlanning">
          <FocusPanel
            show={this.state.isWorkerModalOpen || this.state.isWorkerModalConfirmOpen || this.state.isVehicleModalOpen}/>
          <div className="DailyPlanningTitleBar">
            <div>
              {labels.dailyPlanning}
            </div>
            <div className="circuit">
              <CircuitSelect className={""} onChange={(value) => {
                this.onCircuitChange(value)
              }} value={this.state.circuitFilter} disabled={this.state.circuitFilterDisabled}
              />
            </div>
          </div>

          {this.state.monthlyPlanning && this.state.monthlyPlanning.status != StatusEnum.DAILY_PLANNING_COMPLETED ?
            <InfoBar type="alert"
                     text={I18n.get().DailyPlanning.warningNotCompleted + this.state.day.startOf("month").toFormat(GfDateUtils.FORMAT_MONTH)}/> : null
          }
          {this.state.dailyVehicles &&
          this.state.dailyVehiclesStartingOnDate.length == 0 ?
            <InfoBar type="warning"
                     text={I18n.get().DailyVehicle.warningNoData + this.state.day.toFormat(GfDateUtils.DATE_FORMAT)}/> : null
          }


          {this.state.isWorkerModalOpen ?
            <WorkerServiceTurnModal
              onSave={(records, deletedRecords) => this.processDailyRecords(records, deletedRecords)}
              date={this.state.day}
              isWorkerCreationModal={this.state.isWorkerModalCreateWorker}
              onCancel={() => this.toggleSelectWorker()}
              recordsDto={this.state.selectedDailyWorkers}
              radioMap={radioCodeMap}
              containerVehicles={this.state.containerServiceVehicle}
              vehicleNeedMap={this.state.vehiclesMap}
              shifts={this.state.shifts.filter(sh => !WorkerServiceTurnModal.isShiftTimeOff(sh.shiftCode))}
              worker={this.state.selectedWorker}
              needs={this.state.needs}
            /> :
            null}

          {this.state.isAddVehicleModalOpen ?
            <AddVehicleServiceModal
              vehicles={this.state.allVehicles}
              containerVehicle={this.state.containerVehicleToAddTo}
              date={this.state.day}
              onCancel={() => this.setState({isAddVehicleModalOpen: false})}
              onSave={(create) => {
                this.addVehicleDailyRecord(create);
              }}
            /> : null}

          {this.state.isAddWorkerModalOpen ?
            <AddWorkerServiceModal
              workers={this.state.allWorkers}
              shifts={this.state.shifts}
              containerVehicle={this.state.containerVehicleToAddTo}
              date={this.state.day}
              onCancel={() => this.setState({isAddWorkerModalOpen: false})}
              onSave={(create) => {
                this.addWorkerDailyRecord(create);
              }}
            /> : null}


          {this.state.isVehicleModalOpen ?
            <VehicleServiceTurnModal onSave={(created, updatedRecords, deletedRecords) => {
              this.processVehicleDailyRecords(created, updatedRecords, deletedRecords);
            }}
                                     radioMap={radioCodeMap}
                                     vehicle={this.state.selectedVehicle}
                                     date={this.state.day}
                                     vehicleNeedMap={this.state.vehiclesMap}
                                     onCancel={() => this.toggleSelectVehicle()}
                                     containerVehicles={this.state.containerServiceVehicle
                                       .filter(c => c.date == day.toFormat(GfDateUtils.STORED_DATE_FORMAT))}
                                     records={this.state.dailyVehicles.filter(r => r.vehicleId ==
                                       this.state.selectedVehicle.id && r.date ==
                                       this.state.day.toFormat(GfDateUtils.STORED_DATE_FORMAT))}

            /> :
            null}


          {this.state.isWorkerModalConfirmOpen ?
            <ConfirmModal body={I18n.get().DailyPlanning.confirmDeleteBody}
                          title={I18n.get().DailyPlanning.confirmDeleteTitle}
                          onClick={() => this.onWorkerConfirmDeleteClick()}
                          onCancel={() => this.toggleConfirmModal()}/>
            :
            null}


          <DailyNavigationBar
            selectedService={selectedService}
            selectedView={selectedView}
            currentDate={day}
            onViewSelected={selectedView => this.onChangeSelectedView(selectedView)}
            onDayChanged={(day) => this.loadDataForDay(day)}
            deselectService={() => {
              this.handleServiceDeselection();
            }}
          />

          {selectedView == SelectedView.WORKERS && <div>
            <SearchInput className="search-input" label="" defaultValue=""
                         placeholder={I18n.get().DailyPlanning.findByNameOrTurn}
                         onChange={(value) => this.onSearchWorker(value)}
                         readonly={false} disabled={false} password={false} errorMessage={""}/>
          </div>}

          {selectedView == SelectedView.VEHICLES && <div>
            <SearchInput className="search-input" label="" defaultValue=""
                         placeholder={I18n.get().DailyPlanning.findVehicle}
                         onChange={(value) => this.onVehicleSearch(value)}
                         readonly={false} disabled={false} password={false} errorMessage={""}/>
          </div>}


          {!selectedService
            ? <div className="DailyServicesTimeLine">

              {selectedView == SelectedView.SERVICES && <DailyServicesTimeLineOverview
                day={day}
                needs={needs}
                shifts={shifts}
                circuitFilter={this.state.circuitFilter}
                dailyWorkers={dailyWorkersStartingOnDate}
                dailyVehicles={dailyVehiclesStartingOnDate}
                onServiceSelect={(selectedService: string) => this.setState({selectedService})}
                onVehicleServiceContainerAdd={(containerVehicle) => {
                  this.setState({containerVehicleToAddTo: containerVehicle, isAddVehicleModalOpen: true})
                }}
              />}

              {selectedView == SelectedView.WORKERS &&
              <div>
                <div><Button className={"add-user"}
                             onClick={() => this.toggleCreateWorker()}>{I18n.get().DailyTimeLine.addWorker}</Button>
                </div>
                <DailyWorkersTimeLine
                  onWorkerSelection={(workerDto) => this.onWorkerSelection(workerDto)}
                  onWorkerDelete={(worker => this.onWorkerDeleteClick(worker))}
                  day={day}
                  radioMap={this.state.radioCodeMap}
                  filter={this.state.filter}
                  allWorkers={allWorkers}
                  circuitFilter={this.state.circuitFilter}
                  activeWorkers={activeWorkers}
                  dailyWorkers={dailyWorkers}
                />
              </div>
              }

              {selectedView == SelectedView.VEHICLES &&
              <div>
                <VehiclesTimeLine
                  onVehicleSelection={(vehicle) => this.onVehicleSelection(vehicle)}
                  dailyPlanningVehicles={this.state.dailyVehicles}
                  radioMap={this.state.radioCodeMap}
                  vehicleNeeds={this.state.vehiclesMap}
                  onVehicleIdLoad={() => {
                  }}
                  allVehicles={this.state.allVehicles}
                  circuitFilter={this.state.circuitFilter}
                  onToggleDeleteConfirmModal={() => {
                  }}
                  day={day}
                  filter={this.state.filter}
                />
              </div>
              }

            </div>
            : <div className="DailyServicesTimeLine">
              <DailyServicesTimeLineDetail
                day={day}
                need={needs.filter(need => need.serviceCode == selectedService)[0]}
                // shifts={shifts}
                radioCodeMap={radioCodeMap}
                allWorkers={allWorkers}
                allVehicles={allVehicles}
                vehicleNeeds={this.state.vehiclesMap}
                circuitFilter={this.state.circuitFilter}
                dailyVehicleForService={dailyVehiclesStartingOnDate.filter(w => w.service == selectedService)}
                dailyWorkersForService={dailyWorkersStartingOnDate.filter(w => w.service == selectedService)}
                dailyContainerVehiclesForService={dailyContainerVehicleStartingOnDate.filter(w => w.service == selectedService)}
                onWorkerSelection={(workerDto) => this.onWorkerSelection(workerDto)}
                onVehicleSelection={(vehicle) => this.onVehicleSelection(vehicle)}
                onVehicleServiceContainerAdd={(containerVehicle) => {
                  this.setState({containerVehicleToAddTo: containerVehicle, isAddVehicleModalOpen: true})
                }}
                onWorkerServiceContainerAdd={(containerVehicle) => {
                  this.setState({containerVehicleToAddTo: containerVehicle, isAddWorkerModalOpen: true})
                }}
              />
            </div>}
        </div>
      </div>
    );
  }

  private handleServiceDeselection() {
    this.setState({selectedService: null, circuitFilterDisabled: false});
  }

  private onChangeSelectedView(selectedView: SelectedView) {
    this.setState({selectedView, filter: ""});
  }

  private async loadDataForDay(targetDay: DateTime, selectedService: string = this.state.selectedService) {
    const [dailyWorkers, activeWorkers, needs, shifts, allVehicles,
      vehicleEntries, planning, containerVehiclesYesterday, containerVehiclesToday, radioCodeIds, allNeedsVehicles] = await Promise.all<any>([
      new DailyPlanningApi().getWorkersByDate(targetDay),
      new WorkerRegistryApi().getAllActiveInDay(targetDay),
      new NeedManagementApi().getAllActiveInDay(targetDay),
      new ShiftRegistryApi().getAll(),
      this.vehicleApi.getOrSearchValid(targetDay,"", 10000, 0, false),
      this.vehiclePlanningApi.getDailyPlanningVehicleByDay(targetDay),
      this.monthlyPlanningApi.getByDate(targetDay.startOf('month')),
      this.containerVehicleApi.getDistinctContainerVehiclesForDate(targetDay.minus({day: 1}).toFormat(GfDateUtils.STORED_DATE_FORMAT)),
      this.containerVehicleApi.getDistinctContainerVehiclesForDate(targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT)),
      this.radioCodeApi.getRadioCodes(0, Number.MAX_SAFE_INTEGER),
      this.needApi.getAllNeedVehicles()
    ])
    this.setState({
      day: targetDay,
      dailyWorkers,
      activeWorkers: activeWorkers.content,
      needs: this.getRelevantForDay(needs.content, targetDay),
      shifts: shifts.content,
      allVehicles: allVehicles.content,
      dailyVehicles: vehicleEntries,
      monthlyPlanning: planning,
      containerServiceVehicle: containerVehiclesYesterday.content.concat(containerVehiclesToday.content),
      // TODO: not check date but only previousDay bool
      dailyWorkersStartingOnDate: dailyWorkers.filter((it) => it.date == targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT) && !it.previousDay),
      dailyVehiclesStartingOnDate: vehicleEntries.filter((it) => it.date == targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT) && !it.previousDay),
      dailyContainerVehicleStartingOnDate: containerVehiclesToday.content.filter((it) => it.date == targetDay.toFormat(GfDateUtils.STORED_DATE_FORMAT)),
      radioCodeIds: radioCodeIds.content,
      needVehicles: allNeedsVehicles.needVehicleDtoList
    }, () => {
      const radioCodesDto = radioCodeIds.content;
      const needVehicles = allNeedsVehicles.needVehicleDtoList
      let radioMap = new Map();
      radioCodesDto.forEach(it => {
        radioMap.set(it.id, it.name);
      });
      let vehicleMap = new Map();
      needVehicles.forEach(it => {
        vehicleMap.set(it.id, it);
      });

      var selectedServiceOnlyIfAllWorkers = (this.state.allWorkers && this.state.allWorkers.length > 0) ? selectedService : null;
      this.setState({radioCodeMap: radioMap, vehiclesMap: vehicleMap, selectedService: selectedServiceOnlyIfAllWorkers});
    })
  }

  private getRelevantForDay(content: NeedDto[], targetDay: DateTime) {
    return content.filter(need => {
      const endDate = DateTime.fromFormat(need.endDate, GfDateUtils.STORED_DATE_FORMAT);
      return endDate >= targetDay;
    });
  }

  private async loadAllWorkers(callback: () => void = () => {}) {
    const workers = await new WorkerRegistryApi().getAll()
    this.setState({allWorkers: workers.content}, callback);
  }

  private onWorkerDTOSelection(worker: WorkerDto) {

  }

  private async onWorkerSelection(worker: Worker) {
    // Manage JC workers
    if (worker.id == null) {
      return;
    }
    if (!this.state.isWorkerModalOpen) {
      let byWorkerIdAndDate = await this.dailyPlanningApi.getByWorkerIdAndDate(worker.id, this.state.day);
      this.setState({
        selectedDailyWorkers: byWorkerIdAndDate.content
      });
    }
    this.setState({selectedWorker: worker});
    this.toggleSelectWorker();
  }

  private async onVehicleSelection(vehicle: Vehicle) {
    // // Manage JC workers
    this.setState({selectedVehicle: vehicle}, () => {
      this.toggleSelectVehicle();
    });
  }

  private toggleSelectWorker() {
    let {isWorkerModalOpen} = this.state;
    isWorkerModalOpen = !isWorkerModalOpen;
    this.setState({
      isWorkerModalOpen: isWorkerModalOpen,
      isWorkerModalCreateWorker: false
    });
  }

  private toggleSelectVehicle() {
    let {isVehicleModalOpen} = this.state;
    isVehicleModalOpen = !isVehicleModalOpen;
    this.setState({
      isVehicleModalOpen: isVehicleModalOpen,
    });
  }

  private toggleCreateWorker() {
    let {isWorkerModalOpen} = this.state;
    isWorkerModalOpen = !isWorkerModalOpen;
    this.setState({
      selectedWorker: this.workerRegistryUtils.getEmptyWorker(),
      selectedDailyWorkers: [],
      isWorkerModalCreateWorker: true, isWorkerModalOpen: isWorkerModalOpen
    })
  }

  private async addVehicleDailyRecord(record: VehicleDailyPlanningDto) {
    const response = await this.vehiclePlanningApi.create(record);
    if (response) {
      this.setState({containerVehicleToAddTo: null, isAddVehicleModalOpen: false }, () => {
        this.loadDataForDay(this.state.day);
      });
    }
  }

  private async addWorkerDailyRecord(record: DailyPlanningWorkerDto) {
    //se il record che sto cercando di creare, è di un worker che ha gia un record
    //per lo stesso giorno ed è sul containerVehicle='LIBERO' allora devo spostare quello

    const existingRecord = this.state.dailyWorkersStartingOnDate.find(w => w.workerid == record.workerid
      && w.date == record.date
      && w.service == "LIBERO"
    );

    if (existingRecord) {
      record.id = existingRecord.id;
      record.plannedTurn = existingRecord.plannedTurn;

      const response = await this.dailyPlanningApi.update(record);
      if (response) {
        this.setState({containerVehicleToAddTo: null, isAddWorkerModalOpen: false }, () => {
          this.loadDataForDay(this.state.day);
        });
      }
    }
    else {
      const response = await this.dailyPlanningApi.create(record);
      if (response) {
        this.setState({containerVehicleToAddTo: null, isAddWorkerModalOpen: false }, () => {
          this.loadDataForDay(this.state.day);
        });
      }
    }
  }

  private async processDailyRecords(records: DailyPlanningWorkerDto[],
                                    deletedRecords: DailyPlanningWorkerDto[]) {
    // Ensure reinforced data entries
    records.forEach(r => r.manuallyCreated = true);
    records.forEach(r => r.workername != null ? r.workername.trim() : null);
    await Promise.all(
      [records.filter(r => r.id == null).map(record => this.dailyPlanningApi.create(record)),
        records.filter(r => r.id != null).map(record => this.dailyPlanningApi.update(record)),
        deletedRecords.map(del => this.dailyPlanningApi.delete(del.id))]
    ).then(() => setTimeout(() => this.loadDataForDay(this.state.day), 1000));
    this.toggleSelectWorker();
  }

  private async processVehicleDailyRecords(created: VehicleDailyPlanningDto[],
                                           updatedRecords: VehicleDailyPlanningDto[],
                                           deletedRecords: VehicleDailyPlanningDto[]) {
    await Promise.all(
      [created.map(record => this.vehiclePlanningApi.create(record)),
        updatedRecords.map(record => this.vehiclePlanningApi.update(record)),
        deletedRecords.map(del => this.vehiclePlanningApi.delete(del.id))]
    ).then(() => setTimeout(() => this.loadDataForDay(this.state.day), 1000));
    this.toggleSelectVehicle();
  }

  private async onWorkerDeleteClick(worker: Worker) {
    this.setState({workerForDeletion: worker, isWorkerModalConfirmOpen: true});
  }

  private async onWorkerConfirmDeleteClick() {
    if (!this.state.workerForDeletion) return;
    const fullName = this.state.workerForDeletion.fullName.trim();

    const fullNameSplit = fullName.split(" ");
    if (fullNameSplit.length > 1) {
      const resolvedNameDto = fullNameSplit[1] + " " + fullNameSplit[0];

      await this.dailyPlanningApi.deleteByWorkerNameAndDate(resolvedNameDto, this.state.day)
        .then(this.onDeleteWorkerReload())
      return;
    }
    await this.dailyPlanningApi.deleteByWorkerNameAndDate(fullName, this.state.day)
      .then(this.onDeleteWorkerReload())
  }

  private onDeleteWorkerReload() {
    return () => {
      this.loadDataForDay(this.state.day);
      this.toggleConfirmModal();
      this.setState({workerForDeletion: null});
    };
  }

  private toggleConfirmModal() {
    let {isWorkerModalConfirmOpen} = this.state;
    isWorkerModalConfirmOpen = !isWorkerModalConfirmOpen;
    this.setState({isWorkerModalConfirmOpen});
  }

  private onSearchWorker(value: string) {
    this.setState({filter: value});
  }

  private onVehicleSearch(value: string) {
    this.setState({filter: value});
  }

  private onCircuitChange(value) {
    this.setState({circuitFilter: value});
  }
}

