import { useDroppable } from "@dnd-kit/core";
import "./ContainerVehicleRow.scss";
import React from "react";
import WorkerCard from "../WorkerCard/WorkerCard";
import { container } from "webpack";
import { WorkerDto } from "../../../api/entity/WorkerDto";
import { getTimeLabel, isMattina, isNotte, isPomeriggio, isSera } from "../DailyOverviewUtils";

interface IProps {
  containerVehicle: any;
  dailyWorkers: any[];
  dailyVehicles: any[];
  vehicleNeed?: any;
  workersMap: Map<string, WorkerDto>;
  shiftMap: Map<string, any>;
  radioCode?: string;
  service?: any;
  needMap?: any;
  onServiceClick: (service: string) => void;
}

export default function ContainerVehicleRow({ containerVehicle, dailyWorkers, dailyVehicles, vehicleNeed, workersMap, shiftMap, radioCode, needMap, onServiceClick }: IProps) {

  if (!dailyWorkers) {
    dailyWorkers = [];
  }
  if (!vehicleNeed) {
    vehicleNeed = {};
  }

  if (containerVehicle.containervehicle == "EM03_7_1") {
    console.log("dailyVehicles", dailyVehicles);
  }

  var need = needMap?.get(containerVehicle.service);

  var vehiclesForTime = splitVehiclesByTime(dailyVehicles, containerVehicle);

  var morningVehicle = vehiclesForTime.morning;
  var afternoonVehicle = vehiclesForTime.afternoon;
  var eveningVehicle = vehiclesForTime.evening;
  var nightVehicle = vehiclesForTime.night;

  var isMorningService = isMattina(containerVehicle, containerVehicle, vehicleNeed);
  var isAfternoonService = isPomeriggio(containerVehicle, containerVehicle, vehicleNeed);
  var isEveningService = isSera(containerVehicle, containerVehicle, vehicleNeed);
  var isNightService = isNotte(containerVehicle, containerVehicle, vehicleNeed);



  var morningSpan = 1;
  var afternoonSpan = 1;
  var eveningSpan = 1;
  var nightSpan = 1;

  if (morningVehicle && afternoonVehicle == morningVehicle) {
    morningSpan++;
    afternoonSpan = 0;

    if (eveningVehicle == morningVehicle) {
      morningSpan++;
      eveningSpan = 0;
    }

    if (nightVehicle == morningVehicle) {
      morningSpan++;
      nightSpan = 0;
    }
  }

  if (afternoonSpan > 0 && afternoonVehicle && eveningVehicle == afternoonVehicle) {
    afternoonSpan++;
    eveningSpan = 0;

    if (nightVehicle == afternoonVehicle) {
      afternoonSpan++;
      nightSpan = 0;
    }
  }

  if (eveningSpan > 0 && eveningVehicle && nightVehicle == eveningVehicle) {
    eveningSpan++;
    nightSpan = 0;
  }



  // if (dailyVehicles.length > 0) {
  //   console.log("morningVehicle", morningVehicle);
  //   console.log("afternoonVehicle", afternoonVehicle);
  //   console.log("eveningVehicle", eveningVehicle);
  //   console.log("nightVehicle", nightVehicle);
  // }



  var workersMattina = dailyWorkers.filter((w) => isMattina(w, containerVehicle, vehicleNeed));
  var workersPomeriggio = dailyWorkers.filter((w) => isPomeriggio(w, containerVehicle, vehicleNeed));
  var workersSera = dailyWorkers.filter((w) => isSera(w, containerVehicle,  vehicleNeed));
  var workersNotte = dailyWorkers.filter((w) => isNotte(w, containerVehicle, vehicleNeed));
  var otherWorkers = dailyWorkers.filter((w) =>
    !workersMattina.includes(w) &&
    !workersPomeriggio.includes(w) &&
    !workersSera.includes(w) &&
    !workersNotte.includes(w)
  );

  const { isOver: isOverMorning, setNodeRef: setNodeRefMorning } = useDroppable({
    id: containerVehicle.containervehicle + "|M",
    disabled: !isMorningService,
  });

  const { isOver: isOverAfternoon, setNodeRef: setNodeRefAfternoon } = useDroppable({
    id: containerVehicle.containervehicle + "|P",
    disabled: !isAfternoonService,
  });

  const { isOver: isOverEvening, setNodeRef: setNodeRefEvening } = useDroppable({
    id: containerVehicle.containervehicle + "|S",
    disabled: !isEveningService,
  });

  const { isOver: isOverNight, setNodeRef: setNodeRefNight } = useDroppable({
    id: containerVehicle.containervehicle + "|N",
    disabled: !isNightService,
  });

  var categoryClass = containerVehicle.category?.toLowerCase();
  if (categoryClass == "em+") {
    categoryClass = "emplus";
  }




  return  <React.Fragment>

    <tr className={ "container-vehicle-row-top " + categoryClass }>
      <td rowSpan={2} valign="top" >
          <div className="container-vehicle-service">
            <div className="container-vehicle-service-top">
              {radioCode && <div className="radiocode">
                {radioCode}
              </div>}

            {need?.circuit && <div className="circuit">
              <span>{need.circuit}</span>
            </div>}
              {containerVehicle.category && <div className="category">
                <span>{containerVehicle.category.toUpperCase()}</span>
              </div>}

            </div>


            <div className="container-vehicle-service-bottom">
              <div className={"container-vehicle-row__vehicle__service" + (containerVehicle.service?" actionable":"")} onClick={() => onServiceClick(containerVehicle.service)}>
                <span>
                  {containerVehicle.service}
                </span>
                <span className="container-vehicle-row__vehicle__name">
                  [{containerVehicle.containervehicle}]
                </span>
              </div>
              <div className="container-vehicle-row__vehicle__time">
                {getTimeLabel(containerVehicle) //, containerVehicle, vehicleNeed)
                }
              </div>

            </div>
          </div>
      </td>
      { morningSpan > 0 && <td className={"vehicleRow " + (morningVehicle?"active":"inactive") } colSpan={morningSpan}><div>{getVehicleLabel(morningVehicle, containerVehicle)}</div></td> }
      { afternoonSpan > 0 && <td className={"vehicleRow " + (afternoonVehicle?"active":"inactive") } colSpan={afternoonSpan}><div>{getVehicleLabel(afternoonVehicle, containerVehicle)}</div></td> }
      { eveningSpan > 0 && <td className={"vehicleRow " + (eveningVehicle?"active":"inactive") } colSpan={eveningSpan}><div>{getVehicleLabel(eveningVehicle, containerVehicle)}</div></td> }
      { nightSpan > 0 && <td className={"vehicleRow " + (nightVehicle?"active":"inactive") } colSpan={nightSpan}><div>{getVehicleLabel(nightVehicle, containerVehicle)}</div></td> }
    </tr>
    <tr className={"container-vehicle-row-bottom " + categoryClass} >
      <td className={"container-vehicle-cell" + (isMorningService ? "":" inactive" ) }  ref={setNodeRefMorning} >
        <div className={"workerSlots" + (isMorningService ? (isOverMorning?" dropHighlight":""):"" ) }>
          { workersMattina.map((worker, index) => {
            return <WorkerCard key={index} worker={ {...worker, suffix: "|M", dto: workersMap.get(worker.workerid) }}  containerVehicle={containerVehicle}  />;
          })}
          <div className="filler"></div>
        </div>
      </td>
      <td className={"container-vehicle-cell " + (isAfternoonService ? "":"inactive") } ref={setNodeRefAfternoon}>
        <div className={"workerSlots" + (isAfternoonService ? (isOverAfternoon?" dropHighlight":""):"" ) }>
          { workersPomeriggio.map((worker, index) => {
                return <WorkerCard key={index} worker={ {...worker, suffix: "|P", dto: workersMap.get(worker.workerid) }} containerVehicle={containerVehicle}  />;
          })}
          </div>
      </td>
      <td className={"container-vehicle-cell " + (isEveningService ? "":"inactive") } ref={setNodeRefEvening}>
        <div className={"workerSlots" + (isEveningService ? (isOverEvening?" dropHighlight":""):"" ) }>
          { workersSera.map((worker, index) => {
                  return <WorkerCard key={index} worker={ {...worker, suffix: "|S", dto: workersMap.get(worker.workerid) }}  containerVehicle={containerVehicle} />;
          })}
        </div>
      </td>
      <td className={"container-vehicle-cell " + (isNightService ? "":"inactive") } ref={setNodeRefNight}>
        <div className={"workerSlots" + (isNightService ? (isOverNight?" dropHighlight":""):"" ) }>
          {
            workersNotte.map((worker, index) => {
                  return <WorkerCard key={index} worker={ {...worker, suffix: "|N", dto: workersMap.get(worker.workerid) }}  containerVehicle={containerVehicle}  />;
          })}
        </div>
      </td>
      { otherWorkers.length > 0 && <td className="container-vehicle-cell other">
        <div className="workerSlots">
          { otherWorkers.map((worker, index) => {
                  return <WorkerCard key={index} worker={ {...worker, suffix: "|O", dto: workersMap.get(worker.workerid) }} containerVehicle={containerVehicle} />;
          })}
        </div>
      </td> }
  </tr>
  </React.Fragment>
    ;

            /*
            worker: {
              serviceend: '08:00:00',
              servicestart: '06:00:00',
              shiftend: '13:30:00',
              shiftstart: '06:00:00',
              turn: 'M84',
              vehicle: null,
              workerid: 1416,
              workername: null
            }
            containerVehicle: {
              category: 'interh',
              containervehicle: 'IN09_88_1',
              date: '2024-11-25',
              endshift: '19:00:00',
              id: 777450,
              radiocodeid: 109,
              service: 'IN09',
              startshift: '12:00:00'
            }

            vehicle: {
              containerVehicle: 'SO05_103_1',
              date: '2024-11-25',
              id: 320268,
              manuallyCreated: false,
              previousDay: undefined,
              radiocodeid: 133,
              service: 'SO05',
              serviceCategory: 'intrah',
              serviceEndTime: '07:00:00',
              serviceStartTime: '20:00:00',
              vehicleEndTime: '07:00:00',
              vehicleId: 348,
              vehicleShortName: 'CATIS29',
              vehicleStartTime: '20:00:00'
            }
            */
  function getVehicleLabel(vehicle: any, containerVehicle?: any) {
    if (!vehicle) {
      return "";
    }

    if (vehicle.vehicleShortName == "MOSS31") {
      console.log("vehicle", vehicle);
    }


    return vehicle?.vehicleShortName + " (" + getTimeLabel(vehicle, null, vehicleNeed) + ")";
  }


  function splitVehiclesByTime(vehicles: any[], containerVehicle: any) : ({morning: any, afternoon: any, evening: any, night: any}) {



    var useContainerVehicle = null;
    var result = {
      morning: vehicles.filter((x) => isMattina(x, useContainerVehicle, vehicleNeed)),
      afternoon: vehicles.filter((x) => isPomeriggio(x, useContainerVehicle, vehicleNeed)),
      evening: vehicles.filter((x) => isSera(x, useContainerVehicle, vehicleNeed)),
      night: vehicles.filter((x) => isNotte(x, useContainerVehicle, vehicleNeed)),
    };

    if (result.afternoon.length > 1) {
      result.afternoon = result.afternoon.filter((x) => x != result.morning[0]);
    }
    if (result.evening.length > 1) {
      result.evening = result.evening.filter((x) => x != result.afternoon[0]);
    }
    if (result.night.length > 1) {
      result.night = result.night.filter((x) => x != result.evening[0]);
    }

    return {
      morning: result.morning[0],
      afternoon: result.afternoon[0],
      evening: result.evening[0],
      night: result.night[0],
    };
  }



}

