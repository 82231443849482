import React from "react";
import Time from "../../../lib/utils/Time";
import {DateTime} from "luxon";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";

export default function DailyPlanningNavigationLabel(props: { currentDate: DateTime }) {
  var days = GfDateUtils.getWeekdays();
  const day = days[(props.currentDate.toJSDate().getDay() + 6)%7]?.toUpperCase();
  return <div>
    {day} {props.currentDate.toJSDate().getDate()} {
    Time.getStringMonth(props.currentDate.toJSDate())
  }</div>
}

