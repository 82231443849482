import React, {Component} from "react";
import "./HrTable.scss";
import Table, {Header} from "../../../components/Table/Table";
import I18n from "../../../lib/I18n";
import {DateTime} from "luxon";
import GfDateUtils from "../../../components/DatePicker/GfDateUtils";
import {DayValuesDto} from "../../../api/entity/DayValuesDto";
import IconPencil from "../../../icons/IconPencil";
import LoginApi from "../../../api/login/LoginApi";
import {Roles} from "../../../api/Roles";

interface Props {
  entries: DayValuesDto[]
  onChange: (id: number, date: DateTime)=> void
}

interface TableEntry {
  day: string,
  morning: string,
  central: string,
  night: string,
  modify: any,
}

interface State {
  table: {
    headers: Header<TableEntry>[];
    items: TableEntry[];
    currentSlice: number;
  };
}

export default class HrTable extends Component<Props, State> {

  public static FIRST_TIME = "00:00-08:30";
  public static SECOND_TIME = "08:30-17:00";
  public static THIRD_TIME = "17:00-24:00";

  constructor(props) {
    super(props);
    this.state = {
      table: {
        currentSlice: 1,
        items: [],
        headers: []
      }
    }
  }

  componentDidMount() {
    this.updateTable();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.entries !== this.props.entries) {
      this.updateTable();
    }
  }

  private openModify(e: DayValuesDto) {
    this.props.onChange(e.id, DateTime.fromFormat(e.date, GfDateUtils.STORED_DATE_FORMAT));
  }

  updateTable() {
    const entries = this.props.entries;
    this.setState({
      table: {
        ...this.state.table,
        headers: [
          {
            name: I18n.get().MonthlyPlanning.viewElapsed.table.day,
            field: "day"
          },
          {
            name: HrTable.FIRST_TIME,
            field: "morning"
          },
          {
            name: HrTable.SECOND_TIME,
            field: "central"
          },
          {
            name: HrTable.THIRD_TIME,
            field: "night"
          },
          {
            name: "",
            field: "modify"
          },
        ], items:
          entries.map((e) => {
            return {
              day: DateTime.fromFormat(e.date, GfDateUtils.STORED_DATE_FORMAT).setLocale("it").toFormat(GfDateUtils.DAY_NR).toUpperCase(),
              morning: e.morning,
              central: e.central,
              night: e.night,
              modify: <div>
                {LoginApi.getRole() == Roles.admin ?
                  <span onClick={() => this.openModify(e)}>
                <IconPencil width={24} height={24}/>
                </span>
                  : null}
              </div>
            };
          })
      }
    });
  }

  render() {
    return <div>
      <Table headers={this.state.table.headers}
             items={this.state.table.items}
             onClick={(i) => console.log(i)}/>
    </div>;
  }
}
