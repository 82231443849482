import React, { BaseSyntheticEvent, Component } from 'react';
import './ShiftModal.scss';
import Button from '../../Button/Button';
import I18n from '../../../lib/I18n';
import { ModalType } from '../ModalType';
import { ShiftDto } from '../../../api/entity/ShiftDto';
import TextInput from '../../TextInput/TextInput';
import Select from '../../Select/Select';
import TimeInputPicker from '../../TimeInputPicker/TimeInputPicker';
import ShiftValidator from '../../../api/worker/validator/ShiftValidator';
import ShiftUtils from '../../../utils/ShiftUtils';
import { RadioBoolean } from '../RadioBoolean/RadioBoolean';

export interface Props {
  title: string;
  modalType: ModalType;
  /** This defines additional classes for the Button */
  className: string;
  /** Disables onclick
   *  @default false
   **/
  shiftDto: ShiftDto;
  onSubmit: (shift: ShiftDto) => void;
  onModify: (shift: ShiftDto) => void;
  onCancel: () => void;
  disabled: boolean;
}

export class ShiftModal extends Component<Props, State> {
  private shiftUtils = new ShiftUtils();
  /************************************************
   * PROPS
   ************************************************/
  static defaultProps: Partial<Props> = {
    title: '',
    className: '',
    onCancel: () => {},
    disabled: false,
  };

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props: Props) {
    super(props);
    this.state = {
      shiftDto:
        this.props.modalType == ModalType.MODIFY
          ? this.props.shiftDto
          : {
              shiftCode: '',
              typology: [this.shiftUtils.SHIFT_TYPOLOGY_DTO[0]],
              description: '',
              time: '',
              fromTime: '',
              toTime: '',
              id: null,
              shiftType: this.shiftUtils.SHIFT_TIME_DTO[0],
              pause: '',
              smontoH: '',
              autoGenerated: false,
              archived: false,
              extraH: '',
              applyExtraAtStart: false,
            },
      shiftModalErrors: { codeTurn: '', typology: '', time: '', fromTime: '', toTime: '', smontoH: '', pause: '', extraH: '', applyExtraAtStart: '' },
    };
  }

  onCancel() {
    this.props.onCancel();
  }

  private onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();
    if (this.state.shiftDto.smontoH == '' || this.state.shiftDto.extraH == '') {
      let smontoH = this.state.shiftDto.smontoH ? this.state.shiftDto.smontoH : '00:00';
      let extraH = this.state.shiftDto.extraH ? this.state.shiftDto.extraH : '00:00';

      this.setState({ shiftDto: { ...this.state.shiftDto, smontoH: smontoH, extraH: extraH } });
    }
    ShiftValidator.validate(this.state.shiftDto, this.props.modalType).then(errors => {
      this.setState({ shiftModalErrors: errors });
      if (ShiftModal.isAnyErrorPresent(errors)) {
        return;
      }
      if (this.props.modalType == ModalType.MODIFY) {
        this.props.onModify(this.state.shiftDto);
        return;
      }
      this.props.onSubmit(this.state.shiftDto);
    });
  }

  private static isAnyErrorPresent(shiftModalErrors: ShiftModalErrors) {
    let values = Object.values(shiftModalErrors);
    return values.filter(a => a.length != '').length > 0;
  }

  private setCode(code: string) {
    this.state.shiftDto.shiftCode = code;
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div className={'Modal ShiftModal ' + this.props.className}>
        <form onSubmit={event => this.onSubmit(event)}>
          <div>
            <h1>{this.props.modalType == ModalType.CREATE ? I18n.get().ShiftRegistry.addNewShift : I18n.get().ShiftRegistry.modifyShift}</h1>
          </div>
          <div className={'form'}>
            <div className={'row row-1'}>
              <TextInput
                disabled={this.props.modalType == ModalType.MODIFY}
                defaultValue={this.state.shiftDto.shiftCode}
                onChange={code => {
                  this.setCode(code);
                  this.setState({
                    ...this.state,
                    shiftModalErrors: { ...this.state.shiftModalErrors, codeTurn: '' },
                  });
                }}
                errorMessage={this.state.shiftModalErrors.codeTurn}
                label={'Codice turno'}
                className={'row-w'}
              />
              <Select
                defaultValue={this.shiftUtils.SHIFT_TYPOLOGY_DTO.indexOf(this.state.shiftDto.typology.join(ShiftDto.separatorTypology))}
                items={this.shiftUtils.SHIFT_TOPOLOGY_LIST}
                label={I18n.get().ShiftRegistry.table.typology}
                className={'row-w height'}
                onChange={index =>
                  this.setState({
                    shiftDto: {
                      ...this.state.shiftDto,
                      typology: this.shiftUtils.SHIFT_TYPOLOGY_DTO[index].split(ShiftDto.separatorTypology),
                    },
                  })
                }
              />
            </div>
            <div className={'row row-2'}>
              <TimeInputPicker
                onCorrectChange={time => this.setState({ shiftDto: { ...this.state.shiftDto, fromTime: time } })}
                label={I18n.get().WorkersRegistry.fromTime}
                placeholder={'00:00'}
                className={'row-w'}
                defaultValue={this.state.shiftDto.fromTime}
                error={this.state.shiftModalErrors.fromTime != ''}
              />
              <span className={'row-w divider'}>-</span>
              <TimeInputPicker
                onCorrectChange={time => this.setState({ shiftDto: { ...this.state.shiftDto, toTime: time } })}
                label={I18n.get().WorkersRegistry.toTime}
                placeholder={'00:00'}
                isToTime={true}
                className={'row-w'}
                defaultValue={this.state.shiftDto.toTime}
                error={this.state.shiftModalErrors.toTime != ''}
              />
              <TimeInputPicker
                onCorrectChange={time => this.setState({ shiftDto: { ...this.state.shiftDto, time: time } })}
                label={I18n.get().ShiftRegistry.shiftModal.timeLength}
                placeholder={'HH:MM'}
                className={'row-w column-2'}
                defaultValue={this.state.shiftDto.time}
                error={this.state.shiftModalErrors.time != ''}
              />
              <TimeInputPicker
                onChange={time => this.setState({ shiftDto: { ...this.state.shiftDto, pause: time } })}
                onCorrectChange={time => this.setState({ shiftDto: { ...this.state.shiftDto, pause: time } })}
                label={I18n.get().ShiftRegistry.shiftModal.pause}
                placeholder={'HH:MM'}
                className={'row-w column-2'}
                defaultValue={this.state.shiftDto.pause}
                error={this.state.shiftModalErrors.pause != ''}
              />
            </div>
            <div className={'row row-1'}>
              <TimeInputPicker
                onCorrectChange={time => this.setState({ shiftDto: { ...this.state.shiftDto, smontoH: time } })}
                label={I18n.get().ShiftRegistry.shiftModal.offTimeLength}
                placeholder={'HH:MM'}
                className={'row-w'}
                defaultValue={this.state.shiftDto.smontoH}
                error={this.state.shiftModalErrors.smontoH != ''}
              />

              <TimeInputPicker
                onCorrectChange={time => this.setState({ shiftDto: { ...this.state.shiftDto, extraH: time } })}
                label={I18n.get().ShiftRegistry.shiftModal.extraTimeLength}
                placeholder={'HH:MM'}
                className={'row-w height'}
                defaultValue={this.state.shiftDto.extraH}
                error={this.state.shiftModalErrors.extraH != ''}
              />
            </div>
            <div className={'row row-1'}>
              <div className={'row-w'}></div>

              <div className={'row-w'}>
                <input
                  type="checkbox"
                  id="applyExtraAtStart"
                  name="applyExtraAtStart"
                  checked={this.state.shiftDto.applyExtraAtStart}
                  onChange={event => this.setState({ shiftDto: { ...this.state.shiftDto, applyExtraAtStart: event.target.checked } })}
                />
                <label htmlFor="applyExtraAtStart">{I18n.get().ShiftRegistry.shiftModal.applyExtraAtStart}</label>
              </div>
            </div>
            <div className={'row row-3'}>
              <TextInput
                defaultValue={this.state.shiftDto.description}
                onChange={descr => this.setState({ shiftDto: { ...this.state.shiftDto, description: descr } })}
                label={I18n.get().ShiftRegistry.shiftModal.description}
                className={'row-w'}
              />
              <Select
                defaultValue={this.shiftUtils.SHIFT_TIME_DTO.indexOf(this.state.shiftDto.shiftType)}
                items={this.shiftUtils.SHIFT_TIME_LABELS}
                label={I18n.get().ShiftRegistry.shiftModal.type}
                className={'row-w height'}
                onChange={index =>
                  this.setState({
                    shiftDto: {
                      ...this.state.shiftDto,
                      shiftType: this.shiftUtils.SHIFT_TIME_DTO[index],
                    },
                  })
                }
              />
            </div>

            <div className={'row row-4'}>
              <div className={'row-w'}>
                <input
                  type="checkbox"
                  id="archived"
                  name="archived"
                  checked={this.state.shiftDto.archived}
                  onChange={event => this.setState({ shiftDto: { ...this.state.shiftDto, archived: event.target.checked } })}
                />
                <label htmlFor="archived">{I18n.get().ShiftRegistry.shiftModal.archived}</label>
              </div>
            </div>
          </div>
          <div>
            <div className={'buttons'}>
              <Button onClick={event => this.onSubmit(event)} className={'add-btn'}>
                {this.props.modalType == ModalType.CREATE ? I18n.get().WorkersRegistry.confirmAddWorker : I18n.get().WorkersRegistry.confirmModifyWorker}
              </Button>
              <Button secondary={true} onClick={() => this.onCancel()} className={'cancel-btn'}>
                {I18n.get().WorkersRegistry.cancelAddWorker}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

interface State {
  shiftDto: ShiftDto;
  shiftModalErrors: ShiftModalErrors;
}

export interface ShiftModalErrors {
  codeTurn: string;
  typology: string;
  fromTime: string;
  toTime: string;
  time: string;
  pause: string;
  smontoH: string;
  extraH: string;
  applyExtraAtStart: string;
}
