import TimeLine from "../../../components/TimeLine/TimeLine";
import React, {Component, ReactNode} from "react";
import {DailyPlanningWorkerDtoNt} from "../../../api/entity/response/nt/DailyPlanningWorkerResponseNt";
import {DateTime} from "luxon";
import {NeedDto} from "../../../api/entity/NeedDto";
import Time from "../../../lib/utils/Time";
import TimeLineData from "../../../components/TimeLine/TimeLineData";
import DailyServiceHeader from "./DailyServicesHeader/DailyServiceHeader";
import DailyServiceCell from "./DailyServiceCell/DailyServiceCell";
import {ShiftDto} from "../../../api/entity/ShiftDto";
import NeedUtils, {ServiceMessages} from "../../../api/need/NeedUtils";
import {VehicleDailyPlanningDto} from "../../../api/entity/VehicleDailyPlanningDto";
import DailyServicesTimeLineDetail from "../DailyServicesTimeLineDetail/DailyServicesTimeLineDetail";
import TimeLineMatrix = TimeLineData.TimeLineMatrix;
import Interval = TimeLineData.Interval;
import {TimeLineUtils} from "../TimeLineUtils";
import {CircuitSelect} from "../../../components/CircuitSelect/CircuitSelect";
import { DailyContainerVehicle } from "../../../api/entity/response/nt/ContainerVehicleResponseNt";


interface Props {
  day: DateTime,
  circuitFilter: string,
  needs: NeedDto[],
  shifts: ShiftDto[],
  dailyVehicles: VehicleDailyPlanningDto[]
  dailyWorkers: DailyPlanningWorkerDtoNt[]
  onServiceSelect: (selectedService: string) => void
  onVehicleServiceContainerAdd: (cv: DailyContainerVehicle) => void
}

interface NeedConstruct {
  need: NeedDto,
  totalActualHumanNeed: number,
  totalActualVehicleNeed: number,
  totalExpectedHumanNeed: number,
  totalExpectedVehicleNeed: number,
}

interface State {
}

type TimeLines = TimeLineMatrix & { service: string, issues: string[] };
export default class DailyServicesTimeLineOverview extends Component<Props, State> {
  /**************************************************
   * LIFECYCLE
   **************************************************/
  render() {
    return <TimeLine
      headers={this.dailyServicesHeaders()}
      timeLines={this.dailServicesTimeLines(
        this.props.dailyWorkers,
        this.props.dailyVehicles)}
    />
  }

  /**************************************************
   * PRIVATE FUNCTIONS
   **************************************************/
  private dailyServicesHeaders(): ReactNode[] {
    const headers: ReactNode[] = []
    const hours = Time.getHoursInDay()
    for (const hour of hours)
      headers.push(<DailyServiceHeader day={this.props.day.toJSDate()} hour={hour}/>)
    return headers
  }

  private dailServicesTimeLines(dailyWorkers: DailyPlanningWorkerDtoNt[], dailyVehicles: VehicleDailyPlanningDto[]): TimeLineData.TimeLineMatrix[] {
    const date = this.props.day

    let {errors, warnings} = this.getServicesErrorsAndWarnings(date, dailyWorkers, dailyVehicles);
    let timeLines: TimeLines[] = [];

    // Show only service requiring at least one vehicle or human.
    let electedNeeds: NeedDto[] = this.getNeedsWithAtLeastOneRequiredVehicleOrHuman();

    const circuitFilter = this.props.circuitFilter;

    let needConstructs: NeedConstruct[] = electedNeeds
      .filter(need => circuitFilter == CircuitSelect.NO_FILTER || need.circuit == circuitFilter)
      .map(need => {
      return {
        need: need,
        totalActualHumanNeed: NeedUtils.getTotalActualServiceHumanNeed(dailyWorkers, need),
        totalActualVehicleNeed: NeedUtils.getTotalActualServiceVehicleNeed(dailyVehicles, need),
        totalExpectedHumanNeed: NeedUtils.getTotalExpectedServiceHumanNeed(date, need),
        totalExpectedVehicleNeed: NeedUtils.getTotalExpectedServiceVehicleNeed(date, need)
      }
    });

    for (const needConstr of needConstructs) {
      timeLines.push(this.getDailyServiceTimeLine(
        needConstr.need,date,dailyWorkers,dailyVehicles,
        errors[needConstr.need.serviceCode],
        warnings[needConstr.need.serviceCode]))
    }

    return this.sortTimelinesByIssuesFirst(timeLines)
  }

  private sortTimelinesByIssuesFirst(timeLines: TimeLines[]){
    timeLines = timeLines.sort(this.byServiceCode);
    const servicesWithIssues = timeLines.filter(a => a.issues.length > 0)
    const servicesWithoutIssues = timeLines.filter(a => a.issues.length == 0)
    return servicesWithIssues.concat(servicesWithoutIssues)
  }

  private getServicesErrorsAndWarnings(date:DateTime, dailyWorkers: DailyPlanningWorkerDtoNt[], dailyVehicles: VehicleDailyPlanningDto[]) {
    const {workerErrors, workerWarnings} = this.getErrorsAndWarningsForWorkers(date, dailyWorkers);
    const {vehicleErrors, vehicleWarnings} = this.getErrorsAndWarningsForVehicles(date, dailyVehicles);

    let errors = this.addServiceMessages(workerErrors, {});
    errors = this.addServiceMessages(vehicleErrors, errors);
    let warnings = this.addServiceMessages(workerWarnings, {});
    warnings = this.addServiceMessages(vehicleWarnings, warnings);
    return {errors, warnings};
  }
  private addServiceMessages(workerErrors: ServiceMessages, map: {}) {
    for (const service in workerErrors) if (workerErrors.hasOwnProperty(service)) {
      if (!map[service]) map[service] = []
      map[service] = map[service].concat(workerErrors[service])
    }
    return map
  }

  private getErrorsAndWarningsForWorkers(date:DateTime, dailyWorkers: DailyPlanningWorkerDtoNt[]) {
    const expectedWorkerNeedMap = NeedUtils.getExpectedWorkerNeedMap(this.props.needs, date);
    const actualWorkerNeedMap = NeedUtils.getActualWorkerNeedMap(dailyWorkers);
    const [workerErrors, workerWarnings] = NeedUtils.compareAndGetErrorsAndWarningsForWorkers(expectedWorkerNeedMap, actualWorkerNeedMap)
    return {workerErrors, workerWarnings};
  }

  private getErrorsAndWarningsForVehicles(date:DateTime, dailyVehicles: VehicleDailyPlanningDto[]) {
    const expectedVehiclesNeedMap = NeedUtils.getExpectedVehicleNeedMap(this.props.needs, date);
    const actualVehiclesNeedMap = NeedUtils.getActualVehicleNeedMap(dailyVehicles);
    const [vehicleErrors, vehicleWarnings] = NeedUtils.compareAndGetErrorsAndWarningsForVehicles(expectedVehiclesNeedMap, actualVehiclesNeedMap)
    return {vehicleErrors, vehicleWarnings};
  }

  private getDailyServiceTimeLine(need: NeedDto,
                                  date:DateTime,
                                  dailyWorkers: DailyPlanningWorkerDtoNt[],
                                  dailyVehicles: VehicleDailyPlanningDto[],
                                  errors: string[],
                                  warnings: string[]):TimeLineMatrix & {service:string, issues:string[]} {
    let timelineIntervals: Interval[] = []
    timelineIntervals = timelineIntervals.concat(TimeLineUtils.getYesterdayNeedIntervals(date, need, this.props.shifts));
    timelineIntervals = timelineIntervals.concat(TimeLineUtils.getTodayNeedIntervals(date, need, this.props.shifts, "#A9E2FC", () => this.props.onServiceSelect(need.serviceCode)));
    // Vehicle entries with positive IDs
    const vehiclesForShowing = dailyVehicles.filter(d => d.vehicleId > DailyServicesTimeLineDetail.EMPTY_ENTRY_ID);

    let totalActualHumanNeed = NeedUtils.getTotalActualServiceHumanNeed(dailyWorkers, need);
    let totalActualVehicleNeed = NeedUtils.getTotalActualServiceVehicleNeed(vehiclesForShowing, need);
    let totalExpectedHumanNeed = NeedUtils.getTotalExpectedServiceHumanNeed(date, need);
    let totalExpectedVehicleNeed = NeedUtils.getTotalExpectedServiceVehicleNeed(date, need);

    let types = TimeLineUtils.getNeedTypes(date, need);

    return {
      // backgroundColor: "#f3f6f8",
      intervals: timelineIntervals,
      service:need.serviceCode,
      issues:errors,
      title: <DailyServiceCell
        name={need.serviceCode}
        types={types}
        errors={errors}
        warnings={warnings}
        actualHumanNeed={totalActualHumanNeed}
        actualVehicleNeed={totalActualVehicleNeed}
        showExpected
        expectedHumanNeed={totalExpectedHumanNeed}
        expectedVehicleNeed={totalExpectedVehicleNeed}
        onSelect={() => this.props.onServiceSelect(need.serviceCode)}
      />
    }
  }

  private getNeedsWithAtLeastOneRequiredVehicleOrHuman() {
    return this.props.needs.filter(need => {
      return NeedUtils.getTotalExpectedServiceHumanNeed(this.props.day, need) > 0 ||
        NeedUtils.getTotalExpectedServiceVehicleNeed(this.props.day, need) > 0;
    });
  }

  private byServiceCode(a, b) {
    if (a.service < b.service) return -1;
    else if (a.service > b.service) return 1;
    else return 0;
  }
}
