import "./DailyEmployeeLine.scss"
import React from "react";

interface Props {
  onClick?: (id, position: Position) => void
  className?: string
  id?: number
  radioCodeName?: string
  turnName: string
  start: string
  end: string
  extraText?: string
  textColor: string
}

export interface Position {
  x: number,
  y: number
}

export default function DailyEmployeeLine(props: Props) {
  let {turnName, start, end, extraText, radioCodeName} = props;
  let text = `${radioCodeName ? `${radioCodeName} - ` : ""} ${turnName} ${start} - ${end}`;
  text = text + (extraText ? " | " + extraText : "");
  return <div className={"DailyEmployeeLine" + (props.className ? " " + props.className : "")}
              onClick={(event) => props.onClick(props.id, {x: event.clientX, y: event.clientY})}
              style={{color: props.textColor}}
              title={text}>{text}</div>
}
